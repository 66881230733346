import React from 'react'
import App from '../../App'
import Table from '../Table'
import UsersTable from './UsersTable'

const Users = () => {

    const tableDatas = [
        // {
        //     email:"mail@mail.com",
        //     walletAddress:"0x5464215d6121d5sad4212321",
        //     qty:"24",
        //     dropdown:"0",
        // },
        // {
        //     email:"mail@mail.com",
        //     walletAddress:"0x5465215d6121d5sad4212321",
        //     qty:"24",
        //     dropdown:"1",
        // },
        // {
        //     email:"mail@mail.com",
        //     walletAddress:"0x5464215d6121d5sad4212321",
        //     qty:"24",
        //     dropdown:"2",
        // },
    ]

    const tableColumns = [
        {
            Header:"E-mail",
            accessor:"email",
            filter:"text",
            Cell:(props) => <p className="paddingTop">{props.value}</p>
        },
        {
            Header:"Wallet Address",
            accessor:"walletAddress",
            filter:"text",
            Cell:(props) => <p className="paddingTop">{props.value}</p>
        },
        {
            Header:"Quantity NFT",
            accessor:"qty",
            filter:"text",
            Cell:(props) => <p className="paddingTop">{props.value}</p>
        },
        {
            Header:"",
            accessor:"dropdown",
            filter:"text",
            Cell:(props) => (<button data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" className="btn btn-sm btn-dark" style={{marginTop:"10px"}}>
                                <i className="fa fa-chevron-down" aria-hidden="true"></i>
                            </button>)
        },
    ]

    

    return (
        <App>
            <UsersTable show={true} data={tableDatas} title="Users"/>
        </App>
    )
}

export default Users
