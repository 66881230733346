import React, { useState } from 'react'
import App from '../../App';
import { authenticate, isAuthenticated } from '../../requests';
import "../../style.css";
import { SECRET } from "../../config";
import { toast, ToastContainer } from 'react-toastify';
import CryptoJS from "crypto-js";
import requests from '../../request';
import { Instance } from '../../axios';

const EditAccountDetails = () => {

    let user = isAuthenticated();
    const [userName, setUserName] = useState(user.username);
    const [email, setEmail] = useState(CryptoJS.AES.decrypt(user.emailAddress, SECRET).toString(CryptoJS.enc.Utf8));
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [newPassword, setNewPassword] = useState({
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
    });

    const submit = async () => {
        if(!password){
            return toast.error('Please enter your current password!')
        }

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test(email)) return toast.error('Please enter valid email address!')

        let requestBody = {
            username: userName,
            password: CryptoJS.AES.encrypt(password, SECRET).toString(),
            adminId: user.id,
            updateBoth: false,
            emailAddress: CryptoJS.AES.encrypt(email, SECRET).toString(),
        }
        if(newPassword.password){
            if(newPassword.password !== newPassword.confirmPassword) return toast.error('new password and confirm password does not match !');
            requestBody['updateBoth'] = true;
            requestBody['newPassword'] = CryptoJS.AES.encrypt(newPassword.password, SECRET).toString();
        }
        setLoading(true);
        const reponse = await Instance.post(requests.updateUser, requestBody)
        .catch((error) => {
            setLoading(false);
            if(error.response){
                toast.error(error.response.data.error.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })

        if(reponse && reponse.data){
            toast.success('Your account information has been updated!')
            setLoading(false);
            authenticate(reponse.data,()=>{
                setUserName(reponse.data.username);
                setPassword('');
                setNewPassword({
                    ...newPassword,
                    password: '',
                    confirmPassword: '',
                });
            })
        }
    }

    return (
        <App>
            <div className="container1 mt-5">
                <div style={{ borderRadius: "8px" }} className="col-10 mt-5 alert alert-info">
                    <div style={{ color: "black" }} className="row mt-4">
                        <div className="col-md-6 form-group">
                            <h3>Account Details</h3>
                            <div className="mt-4">
                                <div className="form-group">
                                    <label style={{opacity:"70%"}}>Username</label>
                                    <input type="text" className="form-control" value={userName} onChange={(e)=>setUserName(e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label style={{opacity:"70%"}}>Email</label>
                                    <input type="text" className="form-control" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label style={{opacity:"70%"}}>Current password</label>
                                    <div className="flex-center-div">
                                        <input 
                                            type={showCurrentPassword ? "text" : "password"} 
                                            className="form-control" 
                                            value={password} 
                                            onChange={(e)=>setPassword(e.target.value)}
                                        />
                                        <i className={showCurrentPassword ? "fa fa-eye-slash eye-icons" : "fa fa-eye eye-icons"} 
                                            aria-hidden="true" 
                                            style={{opacity:"70%"}}
                                            onClick={()=>setShowCurrentPassword(!showCurrentPassword)}
                                        ></i>
                                    </div>
                                </div>

                                <hr className="solid mt-4"/>
                                <div className="form-group">
                                    <label>Change Password</label>
                                </div>
                                <div className="form-group">
                                    <label style={{opacity:"70%"}}>New password</label>
                                    <div className="flex-center-div">
                                        <input 
                                            type={newPassword.showPassword ? 'text' : "password"} 
                                            className="form-control" 
                                            value={newPassword.password} 
                                            onChange={(e)=>setNewPassword({...newPassword, password: e.target.value})}
                                        />
                                        <i className={newPassword.showPassword ? "fa fa-eye-slash eye-icons" : "fa fa-eye eye-icons"} 
                                            aria-hidden="true" 
                                            style={{opacity:"70%"}}
                                            onClick={()=>setNewPassword({
                                                ...newPassword,
                                                showPassword: !newPassword.showPassword
                                            })}
                                        ></i>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label style={{opacity:"70%"}}>Confirm new password</label>
                                    <div className="flex-center-div">
                                        <input 
                                            type={newPassword.showConfirmPassword ? "text" : "password"} 
                                            className="form-control" 
                                            value={newPassword.confirmPassword} 
                                            onChange={(e)=>setNewPassword({...newPassword, confirmPassword: e.target.value})}
                                        />
                                        <i className={newPassword.showConfirmPassword ? "fa fa-eye-slash eye-icons" : "fa fa-eye eye-icons"} 
                                            aria-hidden="true" 
                                            style={{opacity:"70%"}}
                                            onClick={()=>setNewPassword({
                                                ...newPassword,
                                                showConfirmPassword: !newPassword.showConfirmPassword
                                            })}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-10 mt-4 clubButtons mb-5">
                    <button type="button" onClick={submit} disabled={loading} className="btn btn-success px-5">
                            {loading && <><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;</>}SAVE
                    </button>
                </div>
            </div>
            <ToastContainer autoClose={2000}/>
        </App>
    )
}

export default EditAccountDetails;
