import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { isAuthenticated, LogoutApi } from '../requests';
import "../style.css"
import logo from "./imgs/jetcoinWhite.png";

const currentTab = (history, path) => {
    if (history.location.pathname.includes(path)) {
      return { color: "#2ecc72", fontWeight:"500" };
    } else {
      return { color: "#FFFFFF", fontWeight:"500" };
    }
};

const NavBar = ({history}) => {

    let {username} = isAuthenticated()

    return (
        <nav className="navbar navbar-expand-lg py-3 navbar-dark" style={{backgroundColor: "#2f2f3c"}}>
            <div className="col-2 text-center jetCoinLogo">
                <Link className="nav-link navbar-brand font-italic" to="/admin/talents">
                    <img style={{height:"40px",width:"auto"}} src={logo} alt="jetcoin"/>
                </Link>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="col-10 middleTabs">
                    <div className="col-6 offset-3 navbar-nav navBar tabs">
                        <Link style={currentTab(history, "/admin/talents")}
                            className="nav-item nav-link" to="/admin/talents">Talents</Link>
                        <Link style={currentTab(history, "/admin/rewards")} 
                            className="nav-item nav-link" to="/admin/rewards">Rewards</Link>
                        <Link style={currentTab(history, "/admin/users")} 
                            className="nav-item nav-link" to="/admin/users">Users</Link>
                    </div>
                </div>
                <div className="col-2 navbar-nav navBar">
                    {/* <div class="dropdown accountMenu"> */}
                        <Link 
                            style={currentTab(history, "/admin/manage-account")} 
                            className="nav-item nav-link"
                            to="/admin/manage-account"
                            id="dropdownMenuButton" 
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        >{username}</Link>
                        {/* <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link class="dropdown-item" to="/admin/manage-account">Change account details</Link>
                        </div>
                    </div> */}
                    <Link 
                        style={{color:"#FFFFFF"}} 
                        className="nav-item nav-link"
                        onClick={() => {
                            LogoutApi(()=>history.push("/admin"))
                        }}> Logout </Link>
                </div>
            </div>
        </nav>
    )
}

export default withRouter(NavBar);
