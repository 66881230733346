import axios from "axios"
import API from "./config"
import { isAuthenticated } from "./requests"

export const Instance = axios.create({
    baseURL:`${API}`
});

export const setAuthToken = token => {
    if(token){
        let {obj:{data}} = isAuthenticated()
        Instance.defaults.headers.common["token"] = data;
    } else {
        delete Instance.defaults.headers.common["token"]
    }
};

