import React, { useState, useEffect } from "react";
import "./Wallet.css";
import jetcoinLogo from "./imgs/jetcoinWhite.png";
import hero from "./imgs/heroImg.png";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { getAccount, getAllTokens, getTokenURI } from "../web3utils";
import { Instance } from "../axios";
import requests from "../request";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import { SECRET } from "../config";

const Wallet = () => {
	let awsLINK =
		"https://digitalcard-static-files.s3.ap-southeast-1.amazonaws.com";
	const [account, setAccount] = useState("");
	const [loading, setLoading] = useState(false);
	const [authToken, setAuthToken] = useState("");
	const [cardItems, setCardItems] = useState([]);
	const [purchasedTalentIDs, setPurchasedTalentIDs] = useState([]);

	useEffect(async () => {
		if (localStorage.getItem("ttl") < new Date().getTime()) {
			localStorage.removeItem("address");
			localStorage.removeItem("ids");
			localStorage.removeItem("ttl");
		} else {
			setLoading(true);
			if (localStorage.getItem("address")) {
				setAccount(
					CryptoJS.AES.decrypt(
						localStorage.getItem("address"),
						SECRET
					).toString(CryptoJS.enc.Utf8)
				);
				let ids = JSON.parse(localStorage.getItem("ids"));
				if (ids) {
					await fetchTalents(ids);
				} else {
					localStorage.removeItem("ids");
					localStorage.removeItem("ttl");
					await connectWallet();
				}
			}
		}
	}, []);

	const connectWallet = async () => {
		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "INFURA_ID", // required
				},
			},
		};
		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: false, // optional
			providerOptions, // required
		});
		const provider = await web3Modal.connect();

		const web3 = new Web3(provider);
		window.web3 = web3;
		let address = await getAccount(web3);
		setAccount(address);
		localStorage.setItem(
			"address",
			CryptoJS.AES.encrypt(address, SECRET).toString()
		);
		localStorage.setItem("ttl", new Date().getTime() + 1800000);
		await getNFTs(address);
	};

	const getNFTs = async (account) => {
		setLoading(true);
		let tokens = await getAllTokens(account);
		// console.log("all the jet tokens the users have", tokens);
		let ids = [];
		for (let i = 0; i < tokens.length; i++) {
			let item = tokens[i];
			let tokenData = await getTokenURI(item.token_id);
			// console.log("url for metadata of token is: ", tokenData);
			let arr = tokenData?.split("https://jetnft.com/metadata/");

			if (arr && arr.length === 2) {
				let talentsId = arr[1].split("/")[1];
				let categoryId = arr[1].split("/")[0];
				let added = false;
				ids.map((obj) => {
					if (obj["categoryId"] === categoryId) {
						obj["talentsId"] = [...obj["talentsId"], talentsId];
						added = true;
					}
				});
				if (!added) {
					ids = [
						...ids,
						{ categoryId: categoryId, talentsId: [talentsId] },
					];
				}
			}
		}
		for (let i = 0; i < ids.length; i++) {
			ids[i]["talentsId"] = ids[i]["talentsId"].join();
		}
		localStorage.setItem("ids", JSON.stringify(ids));
		await fetchTalents(ids);
	};

	const fetchTalents = async (ids) => {
		setPurchasedTalentIDs(ids);
		let response = await Instance.post(requests.fetTalentsByIDs, ids).catch(
			(error) => {
				if (error.response) {
					toast.error(error.response.data.error.message, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			}
		);
		if (response && response.data) {
			let newArr = [];
			response.data.data.map((obj) => {
				let newObj = {};
				newObj["coverImage"] = `${awsLINK}/${obj["coverImage"][0]}`;
				newObj["coverImageType"] = obj["coverImageType"];
				newObj["firstName"] = obj["talents"]["First Name"]
					? `${obj["talents"]["First Name"]}`
					: obj["talents"]["Brand"]
					? obj["talents"]["Brand"]
					: "";
				newObj["lastName"] = obj["talents"]["Last Name"]
					? `${obj["talents"]["Last Name"]}`
					: obj["talents"]["Model"]
					? obj["talents"]["Model"]
					: "";
				newObj["_id"] = obj["cardNumber"];
				newObj["categoryId"] = obj["talentNumber"];
				newObj["talentId"] = obj["talentId"];
				newArr.push(newObj);
			});
			setCardItems(newArr);
		}
		setLoading(false);
	};

	return (
		<div className="main">
			<div className="containerDiv">
				<div className="headline pt-4">
					<div>
						<img
							style={{ width: "142px" }}
							src={jetcoinLogo}
							alt="jetcoin logo"
						/>
					</div>
					<div
						className="jetConnect"
						onClick={connectWallet}
						style={{ cursor: "pointer" }}
					>
						<span className="badge badges badge-primary font-weight-light mr-3">
							JET
						</span>
						<span className="jetWallet">
							{account
								? "Connected to wallet"
								: "Connect to a wallet"}
						</span>
					</div>
				</div>
				{account ? (
					<div className="row">
						{cardItems.length ? (
							<>
								{cardItems.map((card) => (
									<>
										<div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 my-4">
											<Link
												to={`/metadata/${card.categoryId}/${card._id}/${card.talentId}`}
											>
												<div className="nftCard relative">
													{card.coverImageType ===
														"image" ||
													card.coverImageType ===
														"" ||
													card.coverImageType ===
														undefined
														? (
														<img
															src={
																card.coverImage
															}
															className="coverImage"
															alt=""
														/>
													) : card.coverImageType ===
													  "video" ? (
														<video
															width="100%"
															height="auto"
															controls
															autoplay
														>
															<source
																src={
																	card.coverImage
																}
																type="video/*"
															/>
														</video>
													) : null}
													<div className="hide-div absolute">
														<h5>{`${card.firstName} ${card.lastName}`}</h5>
													</div>
												</div>
											</Link>
										</div>
									</>
								))}
							</>
						) : (
							<div
								className="mt-5 text-center text-light"
								style={{ width: "100%" }}
							>
								{loading ? (
									<div
										class="spinner-border text-light"
										role="status"
									>
										<span class="sr-only">Loading...</span>
									</div>
								) : (
									<h1>No NFTs!</h1>
								)}
							</div>
						)}
					</div>
				) : (
					<div className="heroDiv">
						<div className="text-center">
							<span style={{ color: "white", fontSize: "24px" }}>
								Browse your JET cards
							</span>
						</div>
						<div className="text-center">
							<img
								src={hero}
								alt="hero"
								style={{
									width: "90%",
									height: "auto",
									marginTop: "8px",
								}}
							/>
						</div>
						<div
							className="text-center mt-3"
							onClick={connectWallet}
						>
							<span
								style={{
									fontSize: "24px",
									color: "#026df7",
									cursor: "pointer",
								}}
							>
								{account
									? "Connected to wallet"
									: "Connect Wallet"}
							</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Wallet;
