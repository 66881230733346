import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import App from "../../../App";
import { isAuthenticated } from "../../../requests";
import "../../../style.css";
import AddCategoryModal from "../AddCategoryModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CategoryBar from "./CategoryBar";
import Table from "../../Table";
import { Instance, setAuthToken } from "../../../axios";
import requests from "../../../request";

const Talents = ({ history, match }) => {
	let { obj } = isAuthenticated();
	let awsLINK =
		"https://digitalcard-static-files.s3.ap-southeast-1.amazonaws.com";

	const [talents, setTalents] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState({});
	const [forceReload, setForceReload] = useState(false);
	const [loading, setLoading] = useState(false);

	const createNewObj = (element) => {
		let newObj = {};

		let firstName =
			element["talents"]["First Name"] || element["talents"]["Brand"];
		let lastName =
			element["talents"]["Last Name"] || element["talents"]["Model"];

		newObj["coverImage"] =
			element["coverImage"] && element["coverImage"].length > 0
				? {
						coverImage:
							element["coverImage"] &&
							element["coverImage"].length > 0
								? `${awsLINK}/${element["coverImage"][0]}`
								: "",
						coverImageType: element["coverImageType"]
							? element["coverImageType"]
							: "",
				  }
				: {};
		// newObj["coverImage"] =
		// 	element["coverImage"] && element["coverImage"].length > 0
		// 		? `${awsLINK}/${element["coverImage"][0]}`
		// 		: "";
		// newObj["coverImageType"] = element["coverImageType"]
		// 	? element["coverImageType"]
		// 	: "";

		newObj["nameNFT"] =
			firstName || lastName ? firstName + " " + lastName : "NO NAME";
		newObj["talentId"] = element["cardNumber"];
		newObj["added"] = element["createdAt"].split("T")[0];
		newObj["ownerlist"] = `${element["talentId"]}`;
		newObj["_id"] = element["cardNumber"];
		newObj["rewards"] =
			element.rewards && element.rewards.length ? element.rewards : [];

		return newObj;
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			if (match.params.categoryId) {
				setAuthToken(obj.data);
				const response1 = await Instance.get(
					`${requests.fetchCategoryById}/${match.params.categoryId}`
				).catch((error) => {
					if (error.response) {
						toast.error(error.response.data.error.message, {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
				});

				if (response1 && response1.data.data) {
					let categoryName =
						response1.data.data[0].category["categoryName"];
					setSelectedCategory({
						value: match.params.categoryId,
						label: categoryName,
					});
				}

				setAuthToken(obj.data);
				const response = await Instance.get(
					`${requests.fetchAllTalentsByCategoryId}/${match.params.categoryId}`
				).catch((error) => {
					if (error.response) {
						toast.error(error.response.data.error.message, {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
				});
				if (response && response.data) {
					let newArr = [];
					response.data.data.forEach((element) => {
						newArr.push(createNewObj(element));
					});
					setLoading(false);
					setTalents(newArr);
				}
			} else {
				setAuthToken(obj.data);
				const cateResponse = await Instance.get(
					requests.fetchAllCategory
				).catch((error) => {
					if (error.response) {
						toast.error(error.response.data.error.message, {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
				});
				if (cateResponse && cateResponse.data) {
					let category = cateResponse.data.data.filter(
						(cate) =>
							cate.category.categoryName === "Soccer Players"
					);
					if (category.length > 0) {
						setSelectedCategory({
							value: category[0]["categoryId"],
							label: category[0]["category"]["categoryName"],
						});
						const response = await Instance.get(
							`${requests.fetchAllTalentsByCategoryId}/${category[0]["categoryId"]}`
						).catch((error) => {
							if (error.response) {
								toast.error(error.response.data.error.message, {
									position: toast.POSITION.TOP_RIGHT,
								});
							}
						});

						if (response && response.data) {
							let newArr = [];
							response.data.data.forEach((element) => {
								newArr.push(createNewObj(element));
							});
							setTalents(newArr);
						}
						history.push(
							`/admin/talents/${category[0]["categoryId"]}`
						);
					} else {
						if (cateResponse.data.data.length > 0) {
							setTalents([]);
							setSelectedCategory({
								value: cateResponse.data.data[0]["categoryId"],
								label: cateResponse.data.data[0]["category"][
									"categoryName"
								],
							});
							const response = await Instance.get(
								`${requests.fetchAllTalentsByCategoryId}/${cateResponse.data.data[0]["categoryId"]}`
							).catch((error) => {
								if (error.response) {
									toast.error(
										error.response.data.error.message,
										{
											position: toast.POSITION.TOP_RIGHT,
										}
									);
								}
							});
							if (response && response.data) {
								let newArr = [];
								response.data.data.forEach((element) => {
									newArr.push(createNewObj(element));
								});
								setTalents(newArr);
							}
							setLoading(false);
							history.push(
								`/admin/talents/${cateResponse.data.data[0]["categoryId"]}`
							);
						}
					}
					setLoading(false);
				}
			}
		};

		fetchData();
	}, []);

	const handleSelect = async (selectedOption) => {
		setLoading(true);
		setSelectedCategory(selectedOption);
		setAuthToken(obj.data);
		const response = await Instance.get(
			`${requests.fetchAllTalentsByCategoryId}/${selectedOption.value}`
		).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.error.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		});

		if (response && response.data) {
			let newArr = [];
			setTalents([]);
			response.data.data.forEach((element) => {
				newArr.push(createNewObj(element));
			});
			setTalents(newArr);
		}
		setLoading(false);
		history.push(`/admin/talents/${selectedOption.value}`);
		setForceReload(!forceReload);
	};

	const imgCol = (data) => (
		<div className="col-1 p-0">
			{data.coverImageType === "" || data.coverImageType === "image" ? (
				<img
					src={data.coverImage}
					alt="No image found!"
					style={{
						height: "50px",
						width: "90px",
						borderRadius: "10px",
					}}
				/>
			) : data.coverImageType === "video" ? (
				<video
					width="90px"
					height="auto"
					// controls
					autoplay
					style={{
						display: data.coverImage !== "" ? "block" : "none",
					}}
					id="coverVideo"
					src={data.coverImage}
					type="video/*"
				></video>
			) : null}
		</div>
	);
	
	const ownerListRender = (props) => (
		<div className="tableData paddingTop1">
			<div>
				<Link to="#" style={{ borderRadius: "5px" }}>
					<button className="btn btn-sm btn-dark">Owner List</button>
				</Link>
			</div>
			&nbsp;
			<div>
				<Link
					to={`/admin/talents/edit/${selectedCategory.value}/${props
						.split(" ")
						.pop()}`}
					className="editButton"
					style={{ display: "inline" }}
				>
					<button type="button" className="btn btn-sm btn-dark">
						<i className="fa fa-pencil" aria-hidden="true"></i>
						&nbsp; EDIT
					</button>
				</Link>
			</div>
		</div>
	);

	const tableColumns = [
		{
			Header: "",
			accessor: "coverImage",
			Cell: (props) => imgCol(props.value),
		},
		{
			Header: "Name NFT",
			accessor: "nameNFT",
			Cell: (props) => <p className="paddingTop">{props.value}</p>,
		},
		{
			Header: "Talent-ID",
			accessor: "talentId",
			Cell: (props) => <p className="paddingTop">{props.value}</p>,
		},
		{
			Header: "Rewards",
			accessor: "rewards",
			Cell: (props) => (
				<p className="paddingTop">
					{props.value.length
						? props.value.map(
								(reward, idx) =>
									`${reward.data.rewards.name}${
										idx !== props.value.length - 1
											? ", "
											: ""
									}`
						  )
						: "N/A"}
				</p>
			),
		},
		{
			Header: "Added",
			accessor: "added",
			Cell: (props) => <p className="paddingTop">{props.value}</p>,
		},
		{
			Header: "",
			accessor: "ownerlist",
			Cell: (props) => ownerListRender(props.value),
		},
	];

	return (
		<App>
			<CategoryBar
				reload={forceReload}
				history={history}
				selectedCategory={selectedCategory}
				handleSelect={handleSelect}
			/>
			<AddCategoryModal reload={forceReload} setReload={setForceReload} />
			<div className="mt-4">
				{!loading ? (
					talents.length > 0 &&
					Object.keys(selectedCategory).length > 0 ? (
						<Table
							data={talents}
							columns={tableColumns}
							title={`${selectedCategory.label}`}
							btnLink={`/admin/talents/add/${selectedCategory.value}`}
							btnName="ADD NEW CARD"
						/>
					) : (
						<>
							{Object.keys(selectedCategory).length > 0 && (
								<div className="container1 mt-5">
									<div className="row">
										<div className="col-md-7">
											<h3 className="p-2">
												{selectedCategory.label}
											</h3>
										</div>
										<div className="col-md-5">
											<div className="row">
												<div className="col-md-7">
													{/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
												</div>
												<div className="col-md-5 text-center">
													<Link
														to={`/admin/talents/add/${selectedCategory.value}`}
														type="button"
														className="btn btn-success floatRight px-4 my-1"
													>
														<p className="p-0 m-0">
															ADD NEW CARD
														</p>
													</Link>
												</div>
											</div>
										</div>
										<div
											style={{ width: "100%" }}
											className="text-center mt-5"
										>
											<h4>No Records Found</h4>
										</div>
									</div>
								</div>
							)}
						</>
					)
				) : (
					<div style={{ width: "100%" }} className="text-center mt-5">
						<div class="spinner-border text-info" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				)}
			</div>
			<ToastContainer autoClose={2000} />
		</App>
	);
};

export default Talents;
