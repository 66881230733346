import API from "./config"
import jwt_decode from "jwt-decode"

export const authenticate = (data, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("jwt", JSON.stringify(data));
        next();
    }
};

export const isAuthenticated = () => {
    if(localStorage.getItem("jwt")){
        let {obj:{data}} = JSON.parse(localStorage.getItem("jwt"));
        const decodedToken = jwt_decode(data)
        if (decodedToken.exp * 1000 < Date.now()) {
            localStorage.clear();
        } 
    }
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("jwt")) {
        return JSON.parse(localStorage.getItem("jwt"));
    } else {
        return false;
    }
};

export const LogoutApi = (next) => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("jwt");
      next();
    }
  };


// export const addSubheadings = (data,token) => {
//     return fetch(`${API}/admin/addSubheading`,{
//         method:"POST",
//         headers:{
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             token: `${token}`
//         },
//         body: JSON.stringify({data})
//     })
//     .then((response)=>response.json())
//     .catch((err)=>console.log("addSubheading",err))
// }

// export const postHeadings = (data,token) => {
//     return fetch(`${API}/admin/addHeading`,{
//         method:"POST",
//         headers:{
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             token: `${token}`
//         },
//         body: JSON.stringify({data})
//     })
//     .then((response)=>response.json())
//     .catch((err)=>console.log("addSubheading",err))
// }

// export const addNewCategory = (category,token) => {
//     return fetch(`${API}/admin/addCategory`,{
//         method:"POST",
//         headers:{
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             token: `${token}`
//         },
//         body: JSON.stringify(category)
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("ADD NEW CATEGORY",err))
// }

// export const getCategoryById = (token, cateId) => {
//     return fetch(`${API}/admin/categoryById/${cateId}`,{
//         method:"GET",
//         headers:{
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             token: `${token}`
//         },
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("GET CATEGORY BY ID",err))
// }

// export const getAllCategories = (token) => {
//     return fetch(`${API}/admin/allcategory`,{
//         method:"GET",
//         headers:{
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             token: `${token}`
//         },
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("GET ALL CATEGORIES",err))
// }

// export const getAllHeadings = (token) => {
//     return fetch(`${API}/admin/allheading`,{
//         method:"GET",
//         headers:{
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             token: `${token}`
//         },
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("GET ALL CATEGORIES",err))
// }

// export const getAllSubHeadings = (token) => {
//     return fetch(`${API}/admin/allsubheading`,{
//         method:"GET",
//         headers:{
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             token: `${token}`
//         },
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("GET ALL SUB HEADINGS",err))
// }

// export const addTalent = (token,data) => {
//     return fetch(`${API}/admin/addTalents`,{
//         method:"POST",
//         headers:{
//             Accept: "application/json",
//             token: `${token}`
//         },
//         body:data,
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("ADD TALENT",err))
// }

// export const getAllTalents = (token) => {
//     return fetch(`${API}/admin/allTalents`,{
//         method:"GET",
//         headers:{
//             Accept: "application/json",
//             token: `${token}`
//         },
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("GET ALL TALENTS",err))
// }

// export const getTalentById = (talentId,token) => {
//     return fetch(`${API}/admin/talentsById/${talentId}`,{
//         method:"GET",
//         headers:{
//             Accept: "application/json",
//             token: `${token}`
//         },
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("GET TALENT BY ID",err))
// }

// export const getAllTalentsByCateID = (token,cateId) => {
//     return fetch(`${API}/admin/categoryByTalents/${cateId}`,{
//         method:"GET",
//         headers:{
//             Accept: "application/json",
//             token: `${token}`
//         },
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("GET TALENT BY CATE-ID",err))
// }

// export const deleteTalentById = (token,talentId) => {
//     return fetch(`${API}/admin/removeTalents/${talentId}`,{
//         method:"POST",
//         headers:{
//             Accept: "application/json",
//             token: `${token}`
//         },
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("DELETE TALENT BY ID",err))
// }

// export const updateTalentById = (token,talentId,data) => {
//     return fetch(`${API}/admin/updateTalents/${talentId}`,{
//         method:"POST",
//         headers:{
//             Accept: "application/json",
//             token: `${token}`
//         },
//         body:data,
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("UPDATE TALENT",err))
// }

// export const addReward = (token,data) => {
//     return fetch(`${API}/admin/addRewards`,{
//         method:"POST",
//         headers:{
//             Accept: "application/json",
//             token: `${token}`
//         },
//         body:data,
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("ADD REWARDS",err))
// }

// export const removeReward = (token,rewardId) => {
//     return fetch(`${API}/admin/removeRewards/${rewardId}`,{
//         method:"POST",
//         headers:{
//             Accept: "application/json",
//             token: `${token}`
//         },
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("ADD REWARDS",err))
// }

// export const getRewardById = (token,rewardId) => {
//     return fetch(`${API}/admin/rewardsById/${rewardId}`,{
//         method:"GET",
//         headers:{
//             Accept: "application/json",
//             token: `${token}`
//         },
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("ADD REWARDS",err))
// }

// export const updateRewardById = (token,rewardId,data) => {
//     return fetch(`${API}/admin/updateRewards/${rewardId}`,{
//         method:"POST",
//         headers:{
//             Accept: "application/json",
//             token: `${token}`
//         },
//         body: data
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("ADD REWARDS",err))
// }

// export const getAllRewards = (token) => {
//     return fetch(`${API}/admin/allRewards`,{
//         method:"GET",
//         headers:{
//             Accept: "application/json",
//             token: `${token}`
//         },
//     })
//     .then(response=>response.json())
//     .catch((err)=>console.log("GET REWARDS",err))
// }
