import React, { Fragment, useEffect, useState, useLayoutEffect } from "react";
import "../style.css";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Carousel from "./Carousel/Carousel";
import { AppContainer } from "./Carousel/components";
import jetcoinLogo from "./imgs/jetcoinWhite.png";
import "./Wallet.css";
import TwitterIcon from "@material-ui/icons/Twitter";
import RedditIcon from "@material-ui/icons/Reddit";
import TelegramIcon from "@material-ui/icons/Telegram";
import InstagramIcon from "@material-ui/icons/Instagram";
import MediumIcon from "./imgs/medium.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import PinterestIcon from "@material-ui/icons/Pinterest";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	makeStyles,
	TextField,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import MuiPhoneNumber from "material-ui-phone-number";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";
import { Instance } from "../axios";
import requests from "../request";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
import Viewer from "react-viewer";

const useWindowSize = () => {
	const [size, setSize] = useState(0);
	useLayoutEffect(() => {
		const updateSize = () => {
			setSize(window.innerWidth);
		};
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
};

const useStyles = makeStyles((theme) => ({
	buttonColor: {
		background: "#343a40",
		color: "white",
	},
	buttonSuccess: {
		backgroundColor: "#343a40",
		color: "white",
		"&:hover": {
			backgroundColor: "#343a40",
		},
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
		zIndex: 1,
	},
	cancelButton: {
		color: "gray",
	},
	redeemButton: {
		color: "white",
	},
}));

const NFTDetails = ({ data, accountId }) => {
	let awsLINK =
		"https://digitalcard-static-files.s3.ap-southeast-1.amazonaws.com";

	const classes = useStyles();
	const [currentTab, setCurrentTab] = useState("");
	const [currentTabIdx, setCurrentTabIdx] = useState("");
	const [btnIdx, setBtnIdx] = useState("");
	const [clickedRewardId, setClickedRewardId] = useState("");
	const [success, setSuccess] = useState(false);
	const [visible, setVisible] = useState(false);
	const buttonClassname = clsx({
		[classes.buttonSuccess]: success,
		[classes.buttonColor]: !success,
	});
	const [action, setAction] = useState("");
	const width = useWindowSize();

	let talentId = data.talentId;

	const [coverImage, setCoverImage] = useState(
		data.coverImage.length ? `${awsLINK}/${data.coverImage[0]}` : ""
	);
	let videos = data.videos.map((video) => `${awsLINK}/${video}`);
	let images = data.images.map((img) => `${awsLINK}/${img}`);
	const [galleryVideos, setGalleryVideos] = useState([
		...data.videoLink,
		...videos,
	]);
	const [galleryPhotos, setGalleryPhotos] = useState([
		...data.imageLink.map((link) => ({ src: link, alt: "link" })),
		...images.map((src) => ({ src, alt: "src" })),
	]);
	const [bio, setBio] = useState(data.talents);
	let navBarArr = data.categoryData.category.heading.filter(
		(heading) => heading !== "Cover"
	);
	const [navBarItems, setNavBarItems] = useState([
		"cardValues",
		"coverImage",
		...navBarArr,
	]);

	const [open, setOpen] = React.useState(false);
	const [openReward, setOpenReward] = React.useState(false);
	const [displayImage, setDisplayImage] = useState("");
	const [rewards, setRewards] = useState(data.rewards);
	const [rewardsInfo, setRewardsInfo] = useState({
		name: "",
		email: "",
		contactNumber: "",
		accountId,
	});
	const [displaySocialMedia, setDisplaySocialMedia] = useState(
		data?.categoryData?.category?.subheading["Social Media"]?.length
			? data.categoryData.category.subheading["Social Media"].filter(
					(key) => data.talents[key] && data.talents[key] !== "N/A"
			  )
			: []
	);

	useEffect(() => {
		if (!displaySocialMedia?.length) {
			setNavBarItems(
				["cardValues", "coverImage", ...navBarArr].filter(
					(item) => item !== "Social Media"
				)
			);
		}
	}, [displaySocialMedia]);

	const { name, email, contactNumber } = rewardsInfo;

	const handleInputChange = (e) => {
		setRewardsInfo({ ...rewardsInfo, [e.target.name]: e.target.value });
	};

	const handlePhoneInput = (value) => {
		if (value) {
			setRewardsInfo({
				...rewardsInfo,
				contactNumber: value,
			});
		}
	};

	const redeemReward = async () => {
		setSuccess(false);
		const response = await Instance.post(
			`${requests.claimReward}/${talentId}/${clickedRewardId}`,
			rewardsInfo
		).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.error.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		});
		if (response && response.data) {
			toast.success(
				"We have received your request to redeem your reward and we will contact you shortly.",
				{
					position: toast.POSITION.TOP_RIGHT,
				}
			);
			setSuccess(true);
			setBtnIdx("");
			setOpenReward(false);
			setRewardsInfo({
				...rewardsInfo,
				name: "",
				email: "",
				contactNumber: "",
			});
			let rewardsArr = [...rewards];
			rewardsArr[btnIdx]["isRedeemed"] = rewardsArr[btnIdx]["isRedeemed"]
				? 0
				: 1;
			setRewards(rewardsArr);
		}
	};

	const handleClickOpen = (idx) => {
		// setOpen(true);
		setDisplayImage(galleryPhotos[idx]);

		let modal = document.getElementById("myModal");
		modal.style.display = "block";
	};

	const rewardDialog = (rewardId, idx) => {
		setClickedRewardId(rewardId);
		setOpenReward(true);
		setBtnIdx(idx);
	};

	const rewardDialogClose = () => {
		setOpenReward(false);
		setClickedRewardId("");
		setBtnIdx("");
		setRewardsInfo({
			...rewardsInfo,
			name: "",
			email: "",
			contactNumber: "",
		});
	};

	const handleClose = () => {
		setOpen(false);
		setDisplayImage("");
	};

	useEffect(() => {
		if (data.categoryData.category.heading.length > 0) {
			if (data.categoryData.category.heading[0] === "Cover") {
				setCurrentTab(data.categoryData.category.heading[1]);
			} else {
				setCurrentTab(data.categoryData.category.heading[0]);
			}
		}
	}, []);

	let clubData = {};
	if (data.talents["Club Affiliation"]) {
		clubData = JSON.parse(data.talents["Club Affiliation"]);
	}

	const redeemRewardDialogBox = () => (
		<Dialog
			open={openReward}
			onClose={rewardDialogClose}
			aria-labelledby="form-dialog-title"
			className="rewardModal"
		>
			<DialogTitle id="form-dialog-title" className="textWhite">
				Redeem Reward
			</DialogTitle>
			<DialogContent>
				<DialogContentText style={{ color: "white" }}>
					To redeem this reward, please enter your name, email address
					and contact number here.
				</DialogContentText>
				<TextField
					autoFocus
					margin="normal"
					className="rewardInput"
					variant="filled"
					id="name"
					name="name"
					InputLabelProps={{
						shrink: true,
					}}
					value={name}
					onChange={handleInputChange}
					label="Name"
					type="text"
					fullWidth
				/>
				<TextField
					margin="normal"
					className="rewardInput"
					variant="filled"
					id="email"
					name="email"
					InputLabelProps={{
						shrink: true,
					}}
					value={email}
					onChange={handleInputChange}
					label="Email Address"
					type="email"
					fullWidth
				/>
				<MuiPhoneNumber
					margin="normal"
					className="rewardInput"
					variant="filled"
					id="telnumber"
					name="contactNumber"
					value={contactNumber}
					onChange={handlePhoneInput}
					data-cy="user-phone"
					defaultCountry={"us"}
					label="Contact Number"
					type="tel"
					fullWidth
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={rewardDialogClose}
					className={classes.cancelButton}
				>
					Cancel
				</Button>
				<Button onClick={redeemReward} className={classes.redeemButton}>
					Redeem
				</Button>
			</DialogActions>
		</Dialog>
	);

	const socialMedia = () => (
		<>
			{displaySocialMedia.length ? (
				<>
					<div className="text-center textWhite my-3">
						Social Media
					</div>
					{data.categoryData.category.subheading["Social Media"]
						?.length > 0 &&
						data.categoryData.category.subheading[
							"Social Media"
						].map((key) => (
							<>
								{bio[`${key}`] && bio[`${key}`] !== "N/A" ? (
									<div
										className="text-light fade-in mb-4"
										style={{
											backgroundColor: "#32333F",
											borderRadius: "15px",
										}}
									>
										<div className="p-3 text-left">
											<div className="row">
												<div className="col-lg-2 col-md-4 col-3">
													{key === "Twitter" && (
														<TwitterIcon className="socialMediaIcons" />
													)}
													{key === "Reddit" && (
														<RedditIcon className="socialMediaIcons" />
													)}
													{key === "Telegram" && (
														<TelegramIcon className="socialMediaIcons" />
													)}
													{key === "Medium" && (
														<img
															src={MediumIcon}
															className="socialMediaIcons"
														/>
													)}
													{key === "Instagram" && (
														<InstagramIcon className="socialMediaIcons" />
													)}
													{key === "Facebook" && (
														<FacebookIcon className="socialMediaIcons" />
													)}
													{key === "Youtube" && (
														<YouTubeIcon className="socialMediaIcons" />
													)}
													{key === "Pinterest" && (
														<PinterestIcon className="socialMediaIcons" />
													)}
													{key === "Tiktok" && (
														<MusicNoteIcon className="socialMediaIcons" />
													)}
												</div>
												<div className="col-lg-10 col-md-8 col-9 text-left m-auto m-0">
													<p className="my-0 py-0 pl-3 font-weight-normal hideOverflow">
														<strong>
															<Link
																onClick={() =>
																	window.open(
																		bio[
																			`${key}`
																		],
																		"_blank"
																	)
																}
															>
																{bio[`${key}`]}
															</Link>
														</strong>
													</p>
												</div>
											</div>
										</div>
									</div>
								) : null}
							</>
						))}
				</>
			) : null}
		</>
	);

	const showClubAffiliation = () => (
		<>
			<div className="text-center textWhite my-3">Club Affiliation</div>
			<div className="fade-in mb-4" style={{ zIndex: "3" }}>
				<div
					className="text-light"
					style={{ backgroundColor: "#32333F", borderRadius: "15px" }}
				>
					<div className="p-3 text-left">
						{Object.keys(clubData).length > 0 && (
							<>
								{Object.keys(clubData).map((clubInfo) => (
									<div className="table-responsive">
										<table className="table table-sm table-dark">
											<thead>
												<tr
													style={{
														backgroundColor:
															"#32333F",
													}}
												>
													<th scope="col">Season</th>
													<th scope="col">
														{clubInfo}
													</th>
												</tr>
											</thead>
											<tbody>
												{clubData[`${clubInfo}`]
													.length > 0 ? (
													<>
														{clubData[
															`${clubInfo}`
														].map((rowData) => (
															<tr
																style={{
																	backgroundColor:
																		"#32333F",
																}}
															>
																<td className="font-weight-light">
																	{
																		rowData.season
																	}
																</td>
																<td className="font-weight-light">
																	{
																		rowData.league
																	}
																</td>
															</tr>
														))}
													</>
												) : (
													<tr
														style={{
															backgroundColor:
																"rgb(47, 47, 61)",
														}}
													>
														<td colSpan="2">
															NO RECORDS!
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								))}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);

	const showDetails = (navBar) => (
		<>
			<div className="text-center textWhite my-3">{navBar}</div>
			<div
				className="text-light mb-4 mx-3"
				style={{ backgroundColor: "#32333F", borderRadius: "15px" }}
			>
				{navBar && (
					<div className="fade-in">
						<div className="p-3 text-left">
							{data?.categoryData?.category?.subheading[
								`${navBar}`
							] &&
								data?.categoryData?.category?.subheading[
									`${navBar}`
								].length > 0 &&
								data.categoryData.category.subheading[
									`${navBar}`
								].map((key) => (
									<>
										{key !== "Brand" && key !== "Model" ? (
											<div className="mb-2">
												<div
													className="font-weight-bold"
													style={{ overflow: "auto" }}
												>
													{key}:
												</div>
												<div
													title={bio[`${key}`]}
													className={`font-weight-light ${
														key !== "Description" &&
														"hideOverflow"
													}`}
												>
													{key?.includes(
														"Extra Info"
													) ||
													key?.includes("Link") ||
													key?.includes("Explorer") ||
													key?.includes("Market") ? (
														<Link
															onClick={() =>
																window.open(
																	bio[
																		`${key}`
																	],
																	"_blank"
																)
															}
														>
															{bio[`${key}`]}
														</Link>
													) : (
														<>{bio[`${key}`]}</>
													)}
												</div>
											</div>
										) : null}
									</>
								))}
						</div>
					</div>
				)}
			</div>
		</>
	);

	const showCardValues = () => (
		<>
			<div className="text-center textWhite my-3">Card Values</div>
			<div
				className="text-light mb-4"
				style={{ backgroundColor: "#32333F", borderRadius: "15px" }}
			>
				{currentTab && (
					<div className="fade-in">
						<div className="p-3 text-left">
							{/* <div className="row">
                <div className="col-md-6 col-12 mb-2">
                    <p className="font-weight-bold m-0" style={{overflow:"auto"}}>
                        Serial Number: <span className="font-weight-light">{bio["serialNumber"]}</span>
                    </p>
                </div>
                <div className="col-md-6 col-12 mb-2">
                    <p className="font-weight-bold m-0" style={{overflow:"auto"}}>
                        Max Edition: <span className="font-weight-light">{bio["numOfCards"]}</span>
                    </p>
                </div>
            </div> */}
							<div className="row col-12">
								<p
									className="font-weight-bold m-0"
									style={{ overflow: "auto" }}
								>
									Serial Number:{" "}
									<span className="font-weight-light">
										{bio["serialNumber"]}
									</span>
								</p>
							</div>
							<div className="row col-12">
								<p
									className="font-weight-bold m-0"
									style={{ overflow: "auto" }}
								>
									Max Edition:{" "}
									<span className="font-weight-light">
										{bio["numOfCards"]}
									</span>
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);

	const BioData = () => (
		<>
			<div className="text-center textWhite my-3">Biodata</div>
			<div
				className="text-light mb-4"
				style={{ backgroundColor: "#32333F", borderRadius: "15px" }}
			>
				{currentTab &&
					currentTab !== "Photos" &&
					currentTab !== "Videos" && (
						<div className="fade-in">
							<div className="p-3 text-left">
								<h5 className="mb-3">Biodata</h5>
								{data.categoryData.category?.subheading[
									"Biodata"
								]?.length > 0 &&
									data.categoryData.category.subheading[
										"Biodata"
									].map((key) => (
										<>
											{key !== "Height" &&
												key !== "Weight" &&
												key !== "Foot" && (
													<div className="row mb-2">
														<div
															className="col-4 font-weight-bold"
															style={{
																overflow:
																	"auto",
															}}
														>
															{key}:
														</div>
														<div
															className="col-8 font-weight-light"
															style={{
																overflow:
																	"auto",
															}}
														>
															<span>
																{bio[`${key}`]}
															</span>
														</div>
													</div>
												)}
										</>
									))}
							</div>
						</div>
					)}
			</div>
			<div
				className="text-light mb-4"
				style={{ backgroundColor: "#32333F", borderRadius: "15px" }}
			>
				{currentTab &&
					currentTab !== "Photos" &&
					currentTab !== "Videos" && (
						<div className="fade-in">
							<div className="p-3 text-left">
								<h5 className="mb-3">Physical state & more</h5>
								{data.categoryData.category.subheading[
									"Biodata"
								] &&
									data.categoryData.category.subheading[
										"Biodata"
									].length > 0 &&
									data.categoryData.category.subheading[
										"Biodata"
									].map((key) => (
										<>
											{(key === "Height" ||
												key === "Weight" ||
												key === "Foot") && (
												<div className="row mb-2">
													<div
														className="col-4 font-weight-bold"
														style={{
															overflow: "auto",
														}}
													>
														{key}:
													</div>
													<div
														className="col-8 font-weight-light"
														style={{
															overflow: "auto",
														}}
													>
														{bio[`${key}`]}
													</div>
												</div>
											)}
										</>
									))}
							</div>
						</div>
					)}
			</div>
		</>
	);

	const scrollDown = (param) => {
		document.getElementById(`scrollableDiv${param}`).scrollBy(0, 100);
	};

	const scrollUp = (param) => {
		document.getElementById(`scrollableDiv${param}`).scrollBy(0, -100);
	};

	const renderPhotos = () => (
		<Fragment>
			<div className="text-center textWhite my-3">Photos</div>
			<div
				className="Images removeScroll"
				style={{
					height: width >= 768 ? "75vh" : "85vh",
					backgroundColor: "#32333F",
					borderRadius: "15px",
					overflow: "auto",
				}}
			>
				<div
					className="removeScroll verticalCarousel"
					id="scrollableDivPhoto"
				>
					{galleryPhotos.length ? (
						<Splide
							options={{
								rewind: true,
								direction: "ttb",
								heightRatio: 0.6,
								perPage: 1,
								autoHeight: true,
							}}
						>
							{galleryPhotos.map((img, idx) => (
								<SplideSlide>
									<img
										style={{
											width: "100%",
											height: "auto",
											objectFit: "cover",
											cursor: "pointer",
										}}
										src={img.src}
										alt="Image 2"
										onClick={() => {
											setDisplayImage(idx);
											setVisible(true);
										}}
									/>
								</SplideSlide>
							))}
						</Splide>
					) : (
						<div
							className="text-center mt-4"
							style={{ width: "100%" }}
						>
							<h4 className="text-light">No Photos!</h4>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);

	const renderVideos = () => (
		<Fragment>
			<div className="text-center textWhite my-3">Videos</div>
			<div
				className="photos removeScroll"
				style={{
					height: width >= 768 ? "75vh" : "85vh",
					backgroundColor: "#32333F",
					borderRadius: "15px",
					overflow: "auto",
				}}
			>
				<div
					className="removeScroll verticalCarousel"
					id="scrollableDivVideo"
				>
					{galleryVideos.length ? (
						<Splide
							options={{
								rewind: true,
								direction: "ttb",
								heightRatio: 0.6,
								perPage: 1,
								autoHeight: true,
							}}
						>
							{galleryVideos.map((videoSrc) => (
								<SplideSlide>
									<ReactPlayer
										controls
										className="videos"
										url={videoSrc}
									/>
								</SplideSlide>
							))}
						</Splide>
					) : (
						<div
							className="text-center mt-4"
							style={{ width: "100%" }}
						>
							<h4 className="text-light">No Videos!</h4>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);

	const renderCoverImage = () => (
		<Fragment>
			<div className="text-center textWhite my-3">
				{bio["First Name"]
					? bio["First Name"]
					: bio["Brand"] && bio["Brand"] !== "undefined"
					? bio["Brand"]
					: ""}
				&nbsp;
				{bio["Last Name"]
					? bio["Last Name"]
					: bio["Model"] && bio["Model"] !== "undefined"
					? bio["Model"]
					: ""}
			</div>
			<div>
				<img
					style={{
						width: "100%",
						objectFit: "cover",
						borderRadius: "15px",
						marginBottom: width >= 768 ? "" : "5rem",
					}}
					src={coverImage}
				/>
			</div>
		</Fragment>
	);

	const rewardsSection = () => (
		<Fragment>
			<div className="text-center textWhite my-3">Rewards</div>
			<div
				className="Images removeScroll"
				style={{
					height: width >= 768 ? "75vh" : "85vh",
					backgroundColor: "#32333F",
					borderRadius: "15px",
					overflow: "auto",
				}}
			>
				{rewards?.length > 1 && (
					<>
						<div
							className="upArrow"
							onClick={() => scrollUp("Rewards")}
						>
							<div className="arrow">
								<span></span>
							</div>
						</div>
						<div
							className="downArrow"
							onClick={() => scrollDown("Rewards")}
						>
							<div className="arrow">
								<span></span>
							</div>
						</div>
					</>
				)}
				<div
					className="removeScroll fixedHeight"
					id="scrollableDivRewards"
				>
					<div className="row mx-3">
						{rewards?.length > 0 ? (
							rewards.map((reward, idx) => (
								<div
									className="col-12"
									key={idx}
									style={{
										position: "relative",
										marginBottom: "10px",
									}}
								>
									<img
										style={{
											width: "100%",
											height: "310px",
											borderRadius: "10px",
											marginBottom: "60px",
										}}
										src={`${awsLINK}/${reward.data.cover_image[0]}`}
										alt="tshirt"
									/>
									<div
										style={{
											position: "absolute",
											top: "280px",
											width: "80%",
											left: "0",
											right: "0",
											marginLeft: "10%",
											borderRadius: "10px",
											color: "white",
											backgroundColor: "#32333F",
										}}
										className="alert"
									>
										<div className="row">
											<div className="col-7 text-left">
												<p>
													<strong>
														{
															reward.data.rewards
																.name
														}
													</strong>
												</p>
											</div>
											<div
												className="col-5 text-right"
												style={{ position: "relative" }}
											>
												{/* <button type="button" onClick={()=>console.log(idx)} className="btn m-auto p-auto btn-sm btn-dark">Redeem</button> */}
												<Button
													size="small"
													variant="contained"
													disabled={
														btnIdx === idx ||
														reward.isRedeemed === 1
													}
													onClick={() =>
														rewardDialog(
															reward.data
																.rewardsId,
															idx
														)
													}
													className={buttonClassname}
												>
													Redeem
													{btnIdx === idx && (
														<CircularProgress
															size={24}
															className={
																classes.buttonProgress
															}
														/>
													)}
												</Button>
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<div style={{ width: "100%" }}>
								<h1
									className="text-center lead mt-4"
									style={{ color: "white" }}
								>
									No Rewards!
								</h1>
							</div>
						)}
						{clickedRewardId && redeemRewardDialogBox()}
					</div>
				</div>
			</div>
		</Fragment>
	);

	const aboutSection = () => (
		<>
			<div className="text-center textWhite my-3">About</div>
			<div
				className="text-light mb-4 mx-3"
				style={{ backgroundColor: "#32333F", borderRadius: "15px" }}
			>
				<div className="fade-in">
					<div className="p-3 text-left">
						<div className="row mb-2">
							<div
								className="col-12 font-weight-bold"
								style={{ overflow: "auto" }}
							>
								Description:
							</div>
							<div className={`col-12 mt-2 font-weight-light`}>
								{bio["Description"]}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);

	const whiteSection = () => (
		<>
			<div className="text-center textWhite my-3">Whitepaper</div>
			<div
				className="text-light mb-4 mx-3"
				style={{ backgroundColor: "#32333F", borderRadius: "15px" }}
			>
				<div className="fade-in">
					<div className="p-3 text-left">
						<div className="row mb-2">
							<div
								className="col-12 font-weight-bold"
								style={{ overflow: "auto" }}
							>
								Text:
							</div>
							<div className={`col-12 mt-2 font-weight-light`}>
								{bio["Text"]}
							</div>
							<div className="col-12 font-weight-bold hideOverflow">
								Link: &nbsp;
								<Link
									onClick={() =>
										window.open(bio["Link"], "_blank")
									}
								>
									{bio["Link"]}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);

	const firstScreen = () => (
		<div className="row removeScroll">
			<div className="col-lg-4 col-md-6 col-sm-12 mb-3">
				{renderCoverImage()}
			</div>
			<div className="col-lg-4 col-md-6 col-sm-12 mb-3">
				{aboutSection()}
			</div>
			<div className="col-lg-4 col-md-6 col-sm-12 mb-3">
				{navBarItems.includes("Technical Specifications")
					? showDetails("Technical Specifications")
					: navBarItems.includes("Biodata")
					? BioData()
					: navBarItems.includes("Whitepaper")
					? whiteSection()
					: null}
			</div>
		</div>
	);

	const secondScreen = () => (
		<div className="row removeScroll">
			<div className="col-lg-4 col-md-6 col-sm-12 mb-3">
				{navBarItems.includes("Club Affiliation")
					? showClubAffiliation()
					: navBarItems.includes("Career")
					? showDetails("Career")
					: navBarItems.includes("Performance")
					? showDetails("Performance")
					: navBarItems.includes("Token Info")
					? showDetails("Token Info")
					: null}
			</div>
			<div className="col-lg-4 col-md-6 col-sm-12 mb-3">
				{renderPhotos()}
			</div>
			<div className="col-lg-4 col-md-6 col-sm-12 mb-3">
				{renderVideos()}
			</div>
		</div>
	);

	const thirdScreen = () => (
		<div className="row removeScroll">
			<div className="col-lg-4 col-md-6 col-sm-12 mb-3">
				{navBarItems.includes("Social Media") && socialMedia()}
				{navBarItems.includes("Extra Info") &&
					showDetails("Extra Info")}
			</div>
			<div className="col-lg-4 col-md-6 col-sm-12 mb-3">
				{rewardsSection()}
			</div>
			<div className="col-lg-4 col-md-6 col-sm-12 mb-3">
				{showCardValues()}
			</div>
		</div>
	);

	const navBarTab = (navBar) => {
		if (navBar === 2) {
			return (
				<div id={navBar} className="removeScroll">
					{firstScreen()}
				</div>
			);
		} else if (navBar === 3) {
			return (
				<div id={navBar} className="removeScroll">
					{secondScreen()}
				</div>
			);
		} else if (navBar === 1) {
			return (
				<div id={navBar} className="removeScroll">
					{thirdScreen()}
				</div>
			);
		}
	};

	const getOrder = (navBar) => {
		if (navBar === "Biodata") {
			return <div id={navBar}>{BioData()}</div>;
		} else if (navBar === "Social Media") {
			return <div id={navBar}>{socialMedia()}</div>;
		} else if (navBar === "cardValues") {
			return <div id={navBar}>{showCardValues()}</div>;
		} else if (navBar === "Photos") {
			return <div id={navBar}>{renderPhotos()}</div>;
		} else if (navBar === "Videos") {
			return <div id={navBar}>{renderVideos()}</div>;
		} else if (navBar === "Club Affiliation") {
			return <div id={navBar}>{showClubAffiliation()}</div>;
		} else if (navBar === "Rewards") {
			return <div id={navBar}>{rewardsSection()}</div>;
		} else if (navBar === "coverImage") {
			return <div id={navBar}>{renderCoverImage()}</div>;
		} else if (navBar === "About") {
			return <div id={navBar}>{aboutSection()}</div>;
		} else if (navBar === "Whitepaper") {
			return <div id={navBar}>{whiteSection()}</div>;
		} else {
			return <div id={navBar}>{showDetails(navBar)}</div>;
		}
	};

	return (
		<div style={{ minHeight: "100vh", backgroundColor: "#232327" }}>
			<div className="containerDiv">
				<div className="headline pt-4">
					<div>
						<img
							style={{ width: "142px" }}
							src={jetcoinLogo}
							alt="jetcoin logo"
						/>
					</div>
				</div>
				<div
					className="text-left"
					style={{ overflow: "auto", backgroundColor: "#232327" }}
				>
					{currentTabIdx !== "PREV" && (
						<div
							className="leftArrow"
							onClick={() => setAction("PREV")}
						>
							<div className="arrow">
								<span></span>
							</div>
						</div>
					)}
					{currentTabIdx !== "NEXT" && (
						<div
							className="rightArrow"
							onClick={() => setAction("NEXT")}
						>
							<div className="arrow">
								<span></span>
							</div>
						</div>
					)}
					<AppContainer>
						{width >= 768 ? (
							<Carousel
								setCurrentTabIdx={setCurrentTabIdx}
								action={action}
								setAction={setAction}
								title="Carousel"
							>
								{[1, 2, 3].map((navBar) => navBarTab(navBar))}
							</Carousel>
						) : (
							<Carousel
								setCurrentTabIdx={setCurrentTabIdx}
								action={action}
								setAction={setAction}
								title="Carousel"
							>
								{navBarItems.map((navBar) => getOrder(navBar))}
							</Carousel>
						)}
					</AppContainer>
				</div>

				<Viewer
					visible={visible}
					onClose={() => setVisible(false)}
					images={galleryPhotos}
					activeIndex={displayImage}
					attribute={false}
					rotatable
				/>
			</div>
			<ToastContainer />
		</div>
	);
};

export default NFTDetails;
