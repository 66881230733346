import React, { useEffect, useRef, useState } from "react";
import App from "../../App";
import "../../style.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ReactPlayer from "react-player";
import { isAuthenticated } from "../../requests";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Instance, setAuthToken } from "../../axios";
import requests from "../../request";
import { Last } from "react-bootstrap/esm/PageItem";
import { Link } from "react-router-dom";
import { API_URL, API_KEY } from "../../config";
import { nanoid } from "nanoid";
import axios from "axios";

const EditNFTCard = ({ history, match }) => {
	let {
		obj: { data },
	} = isAuthenticated();
	let token = data;

	let talentId = match.params.talentId;
	let categoryId = parseInt(match.params.categoryId);
	let awsLINK =
		"https://digitalcard-static-files.s3.ap-southeast-1.amazonaws.com";

	const [categoryName, setCategoryName] = useState("");
	const [headings, setHeadings] = useState([]);
	const [subHeadings, setSubHeadings] = useState({});
	const [inputs, setInputs] = useState({});
	const [bio, setBio] = useState({
		// FirstName:"",
		// LastName:"",
		serialNumber: "",
		numOfCards: "",
		maxEdition: "",
		eth: "",
		purchaseOnMintable: "",
		tradeOnMintable: "",
		tempImageLink: "",
		tempVideoLink: "",
		galleryLink: "",
		videoLink: "",
		talentID: "",
		formData: new FormData(),
	});
	const [domesticLeague, setDomesticLeague] = useState([]);
	const [domesticCups, setDomesticCups] = useState([]);
	const [internationCups, setInternationCups] = useState([]);
	const [coverImage, setCoverImage] = useState("");
	const [coverVideo, setCoverVideo] = useState("");
	const [galleryPhotos, setGalleryPhotos] = useState([]);
	const [galleryVideos, setGalleryVideos] = useState([]);
	const [rewards, setRewards] = useState([]);
	const [loading, setLoading] = useState(false);
	const [forceReload, setforceReload] = useState(false);
	const [talentData, setTalentData] = useState({});
	const [isPublishing, setIsPublishing] = useState(false);
	const [clubAffiliationData, setClubAffiliationData] = useState({});

	const inputPhoto = useRef(null);
	const inputVideo = useRef(null);

	const {
		// FirstName,
		// LastName,
		serialNumber,
		maxEdition,
		numOfCards,
		eth,
		purchaseOnMintable,
		tradeOnMintable,
		tempImageLink,
		tempVideoLink,
		formData,
	} = bio;

	const getCategory = async () => {
		setAuthToken(token);
		const reponse = await Instance.get(requests.fetchAllCategory).catch(
			(error) => {
				if (error.response) {
					toast.error(error.response.data.error.message, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			}
		);

		if (reponse && reponse.data) {
			let footballData = reponse.data.data.filter(
				(category) => category.categoryId === categoryId
			);
			if (footballData[0].category.categoryName) {
				setCategoryName(footballData[0].category.categoryName);
			}
			if (footballData[0].category.heading) {
				setHeadings(footballData[0].category.heading);
			}
			if (footballData[0].category.subheading) {
				setSubHeadings(footballData[0].category.subheading);
			}
			if (
				footballData[0]?.category?.heading.includes("Club Affiliation")
			) {
				let obj = {};
				if (
					footballData[0].category.subheading["Club Affiliation"]
						?.length
				) {
					footballData[0].category.subheading["Club Affiliation"].map(
						(key) => {
							obj[key] = [];
						}
					);
					setClubAffiliationData(obj);
				}
			}
			if (!match.params.talentId) {
				let obj = {};
				if (
					footballData[0].category.heading &&
					footballData[0].category.subheading
				) {
					footballData[0].category.heading.map((heading) => {
						if (
							footballData[0].category.subheading[heading] &&
							footballData[0].category.subheading[heading]
								.length > 0
						) {
							footballData[0].category.subheading[heading].map(
								(ipFields) => {
									obj[ipFields] = "";
								}
							);
						}
					});
					obj["Brand"] = "";
					obj["Model"] = "";
					setInputs(obj);
				}
			}
		}
	};

	const preload = async (token) => {
		setAuthToken(token);
		const reponse = await Instance.get(
			`${requests.fetchTalentById}/${talentId}`
		).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.error.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		});
		if (reponse && reponse.data) {
			let data = reponse.data;
			setTalentData(data.data);
			if (data.data.rewards) {
				setRewards(data.data.rewards);
			}
			setCategoryName(
				reponse.data.data.categoryData.category.categoryName
			);
			if (data.data["talents"]["Club Affiliation"]) {
				setClubAffiliationData({});
				let clubData = JSON.parse(
					data.data["talents"]["Club Affiliation"]
				);
				let obj = {};
				reponse.data.data.categoryData.category.subheading[
					"Club Affiliation"
				].map((key) => {
					obj[key] = clubData[key];
				});
				setClubAffiliationData(obj);

				// if(clubData["domesticCups"]){
				//     setDomesticCups(clubData["domesticCups"])
				// }
				// if(clubData["domesticLeague"]){
				//     setDomesticLeague(clubData["domesticLeague"])
				// }
				// if(clubData["internationCups"]){
				//     setInternationCups(clubData["internationCups"])
				// }
			}
			setInputs(data.data["talents"]);

			let newObj = { formData: new FormData() };
			newObj["serialNumber"] = data.data["talents"]["serialNumber"]
				? data.data["talents"]["serialNumber"]
				: "";
			newObj["numOfCards"] = data.data["talents"]["numOfCards"]
				? data.data["talents"]["numOfCards"]
				: "";
			// newObj["FirstName"] = data.data["talents"]["FirstName"] ? data.data["talents"]["FirstName"] : ""
			// newObj["LastName"] = data.data["talents"]["LastName"] ? data.data["talents"]["LastName"] : ""
			newObj["maxEdition"] = data.data["talents"]["maxEdition"]
				? data.data["talents"]["maxEdition"]
				: "";
			newObj["purchaseOnMintable"] = data.data["talents"][
				"purchaseOnMintable"
			]
				? data.data["talents"]["purchaseOnMintable"]
				: "";
			newObj["tradeOnMintable"] = data.data["talents"]["tradeOnMintable"]
				? data.data["talents"]["tradeOnMintable"]
				: "";
			newObj["eth"] = data.data["talents"]["eth"]
				? data.data["talents"]["eth"]
				: "";
			newObj["talentID"] = data.data["talentId"];

			setBio({ ...newObj, formData: new FormData() });
			if (
				data.data["coverImage"] &&
				data.data["coverImage"][0] !== "undefined" &&
				data.data["coverImage"][0] !== undefined
			) {
				setCoverImage(`${awsLINK}/${data.data["coverImage"][0]}`);
				formData.set("coverImage", data.data["coverImage"][0]);
			}

			let imageArr = [];
			data.data["images"].forEach((img) => {
				if (img) {
					imageArr.push(`${awsLINK}/${img}`);
				}
			});
			data.data["imageLink"].forEach((links) => {
				imageArr.push(links);
			});
			let videoArr = [];
			data.data["videos"].forEach((video) => {
				videoArr.push(`${awsLINK}/${video}`);
			});
			data.data["videoLink"].forEach((links) => {
				videoArr.push(links);
			});
			setGalleryPhotos(imageArr);
			setGalleryVideos(videoArr);
		}
	};

	useEffect(() => {
		getCategory();
		if (match.params.talentId) {
			preload(token);
		}
	}, [forceReload]);

	const getURLObj = (param) => {
		if (typeof param === "object") {
			return URL.createObjectURL(param);
		} else {
			return param;
		}
	};

	const addNew = (param) => {
		// if (param === "domesticLeague") {
		//     setDomesticLeague([...domesticLeague, { season: "", league: "", id: uuidv4() }])
		// }
		// if (param === "domesticCups") {
		//     setDomesticCups([...domesticCups, { season: "", league: "", id: uuidv4() }])
		// }
		// if (param === "internationCups") {
		//     setInternationCups([...internationCups, { season: "", league: "", id: uuidv4() }])
		// }
		setClubAffiliationData({
			...clubAffiliationData,
			[param]: [
				...clubAffiliationData[param],
				{ season: "", league: "", id: uuidv4() },
			],
		});
	};

	const handleInputs = (e, field, param, idx) => {
		// if (param === "domesticLeague") {
		//     let newArr = [...domesticLeague]
		//     newArr[idx][field] = e.target.value
		//     setDomesticLeague(newArr)
		// }
		// if (param === "domesticCups") {
		//     let newArr = [...domesticCups]
		//     newArr[idx][field] = e.target.value
		//     setDomesticCups(newArr)
		// }
		// if (param === "internationCups") {
		//     let newArr = [...internationCups]
		//     newArr[idx][field] = e.target.value
		//     setInternationCups(newArr)
		// }

		let updatedArr = [...clubAffiliationData[param]];
		updatedArr[idx][field] = e.target.value;
		setClubAffiliationData({
			...clubAffiliationData,
			[param]: updatedArr,
		});
	};

	const deleteEntry = (e, id, param) => {
		// if (param === "domesticLeague") {
		//     let newArr = domesticLeague.filter((obj) => obj.id !== id)
		//     setDomesticLeague(newArr)
		// }
		// if (param === "domesticCups") {
		//     let newArr = domesticCups.filter((obj) => obj.id !== id)
		//     setDomesticCups(newArr)
		// }
		// if (param === "internationCups") {
		//     let newArr = internationCups.filter((obj) => obj.id !== id)
		//     setInternationCups(newArr)
		// }

		let newArr = clubAffiliationData[param].filter((obj) => obj.id !== id);
		setClubAffiliationData({
			...clubAffiliationData,
			[param]: newArr,
		});
	};

	const handleChangeAll = (e, param) => {
		setInputs({ ...inputs, [param]: e.target.value });
		formData.set(param, e.target.value);
	};

	const handleBio = (e) => {
		setBio({ ...bio, [e.target.name]: e.target.value });
		formData.set(e.target.name, e.target.value);
	};

	const loadFile = (e) => {
		let arr = [];
		for (let i = 0; i < e.target.files.length; i++) {
			arr = [...arr, e.target.files[i]];
		}
		if (arr.length) {
			if (e.target.name === "photoFile") {
				setGalleryPhotos([...galleryPhotos, ...arr]);
				document.getElementById("inputPhoto").value = "";
			} else if (e.target.name === "videoFile") {
				setGalleryVideos([...galleryVideos, ...arr]);
				document.getElementById("videoInput").value = "";
			} else {
				if (arr[0].type.split("/")[0] === "video") {
					var image = document.getElementById(`${e.target.name}`);
					image.src = "";
					setCoverVideo(URL.createObjectURL(...arr));
					var video = document.getElementById("coverVideo");
					video.src = URL.createObjectURL(...arr);
				} else {
					setCoverVideo("");
					var image = document.getElementById(`${e.target.name}`);
					image.src = URL.createObjectURL(...arr);
				}
				formData.set(e.target.name, ...arr);
			}
		}
	};

	const deletePost = (index, param) => {
		if (param === "photo") {
			let newArr = galleryPhotos.filter((img, idx) => idx !== index);
			setGalleryPhotos(newArr);
		} else {
			let newArr = galleryVideos.filter((video, idx) => idx !== index);
			setGalleryVideos(newArr);
		}
	};

	const submitData = async () => {
		setLoading(true);
		// let newObj = JSON.stringify({ domesticCups, domesticLeague, internationCups })
		if (headings.includes("Club Affiliation")) {
			formData.set(
				"Club Affiliation",
				JSON.stringify(clubAffiliationData)
			);
		}
		formData.delete("tempImageLink");
		formData.delete("tempVideoLink");
		formData.set("categoryId", categoryId);
		formData.set("talentId", bio["talentID"]);

		Object.keys(inputs).map((ipKey) => {
			if (!formData.get(ipKey)) {
				formData.set(ipKey, "");
			}
		});

		let imageLinks = [];
		let videoLinks = [];
		galleryPhotos.forEach((element) => {
			if (typeof element === "object") {
				formData.append("image", element);
			} else if (element.includes(awsLINK)) {
				if (formData.get("images")) {
					formData.append(
						"images",
						element.replace(`${awsLINK}/`, "")
					);
				} else {
					formData.set("images", element.replace(`${awsLINK}/`, ""));
				}
			} else {
				imageLinks.push(element);
			}
		});
		formData.set("imageLink", JSON.stringify(imageLinks));

		galleryVideos.forEach((element) => {
			if (typeof element === "object") {
				formData.append("video", element);
			} else if (element.includes(awsLINK)) {
				if (formData.get("videos")) {
					formData.append(
						"videos",
						element.replace(`${awsLINK}/`, "")
					);
				} else {
					formData.set("videos", element.replace(`${awsLINK}/`, ""));
				}
			} else {
				videoLinks.push(element);
			}
		});
		formData.set("videoLink", JSON.stringify(videoLinks));
		formData.set("Brand", inputs["Brand"]);
		formData.set("Model", inputs["Model"]);

		if (match.params.talentId) {
			if (!formData.get("coverImage")) {
				formData.set(
					"coverImage",
					coverImage.replace(`${awsLINK}/`, "")
				);
			}

			headings.forEach((heading) => {
				if (subHeadings[heading]) {
					if (subHeadings[heading].length > 0) {
						subHeadings[heading].map((subHeading) => {
							// console.log("INSIDE IT")
							if (
								subHeading !== "imageLink" ||
								subHeading !== "videoLink"
							) {
								if (
									subHeading === "Facebook" &&
									!inputs[subHeading]
								)
									inputs[subHeading] =
										"https://www.facebook.com/jetcoins/";
								if (
									subHeading === "Instagram" &&
									!inputs[subHeading]
								)
									inputs[subHeading] =
										"https://www.instagram.com/jetcoin/";
								if (
									subHeading === "Twitter" &&
									!inputs[subHeading]
								)
									inputs[subHeading] =
										"https://twitter.com/jetcoins";
								formData.set(subHeading, inputs[subHeading]);
								if (
									subHeading === "Telegram" &&
									!inputs[subHeading]
								)
									inputs[subHeading] =
										"https://www.telegram.com/jetcoins";
								formData.set(subHeading, inputs[subHeading]);
								if (
									subHeading === "Reddit" &&
									!inputs[subHeading]
								)
									inputs[subHeading] =
										"https://reddit.com/jetcoins";
								formData.set(subHeading, inputs[subHeading]);
								if (
									subHeading === "Medium" &&
									!inputs[subHeading]
								)
									inputs[subHeading] =
										"https://medium.com/jetcoins";
								formData.set(subHeading, inputs[subHeading]);
							}
						});
					}
				}
			});
			// formData.set("FirstName",FirstName)
			// formData.set("LastName",LastName)
			formData.set("serialNumber", serialNumber);
			formData.set("numOfCards", numOfCards);
			formData.set("maxEdition", maxEdition);
			formData.set("eth", eth);
			formData.set("purchaseOnMintable", purchaseOnMintable);
			formData.set("tradeOnMintable", tradeOnMintable);
			setAuthToken(token);
			const reponse = await Instance.post(
				`${requests.updateTalentById}/${talentData.talentNumber}`,
				formData
			).catch((error) => {
				if (error.response) {
					setLoading(false);
					toast.error(error.response.data.error.message, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			});
			if (reponse && reponse.data) {
				setLoading(false);
				setforceReload(!forceReload);
				toast.success("NFT updated successfully!", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} else {
			console.log("formData 2 => ", formData);
			if (headings.includes("Social Media")) {
				if (
					subHeadings["Social Media"].includes("Facebook") &&
					!formData.get("Facebook")
				)
					formData.set(
						"Facebook",
						"https://www.facebook.com/jetcoins/"
					);
				if (
					subHeadings["Social Media"].includes("Instagram") &&
					!formData.get("Instagram")
				)
					formData.set(
						"Instagram",
						"https://www.instagram.com/jetcoin/"
					);
				if (
					subHeadings["Social Media"].includes("Twitter") &&
					!formData.get("Twitter")
				)
					formData.set("Twitter", "https://twitter.com/jetcoins");
				if (
					subHeadings["Social Media"].includes("Telegram") &&
					!formData.get("Telegram")
				)
					formData.set(
						"Telegram",
						"https://www.telegram.com/jetcoins"
					);
				if (
					subHeadings["Social Media"].includes("Reddit") &&
					!formData.get("Reddit")
				)
					formData.set("Reddit", "https://www.reddit.com/jetcoins/");
				if (
					subHeadings["Social Media"].includes("Medium") &&
					!formData.get("Medium")
				)
					formData.set("Medium", "https://www.medium.com/jetcoins/");
			}
			setAuthToken(token);
			const reponse = await Instance.post(
				requests.postAddTalent,
				formData
			).catch((error) => {
				if (error.response) {
					setLoading(false);
					toast.error(error.response.data.error.message, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			});

			if (reponse && reponse.data) {
				setLoading(false);
				let obj = {};
				headings.map((heading) => {
					if (subHeadings[heading]) {
						if (subHeadings[heading].length > 0) {
							subHeadings[heading].map((ipFields) => {
								obj[ipFields] = "";
							});
						}
					}
				});
				obj["Brand"] = "";
				obj["Model"] = "";
				setInputs(obj);
				setBio({
					...Bio,
					// FirstName:"",
					// LastName:"",
					eth: "",
					maxEdition: "",
					serialNumber: "",
					numOfCards: "",
					purchaseOnMintable: "",
					tradeOnMintable: "",
					formData: new FormData(),
				});
				setDomesticCups([]);
				setDomesticLeague([]);
				setInternationCups([]);
				setGalleryPhotos([]);
				setGalleryVideos([]);
				setCoverImage("");
				if (document.getElementById("coverImage")) {
					document.getElementById("coverImage").src = "";
				}

				setforceReload(!forceReload);
				toast.success("NFT added successfully!", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}
		formData.delete("image");
		formData.delete("images");
		formData.delete("video");
		formData.delete("videos");
		setLoading(false);
	};

	const deleteTalent = async () => {
		setAuthToken(token);
		const response = await Instance.post(
			`${requests.deleteTalentById}/${talentId}`
		).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.error.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		});
		if (response && response.data) {
			let obj = {};
			headings.map((heading) => {
				if (subHeadings[heading]) {
					if (subHeadings[heading].length > 0) {
						subHeadings[heading].map((ipFields) => {
							obj[ipFields] = "";
						});
					}
				}
			});
			setInputs(obj);
			setBio({
				...Bio,
				eth: "",
				maxEdition: "",
				serialNumber: "",
				numOfCards: "",
				purchaseOnMintable: "",
				tradeOnMintable: "",
				formData: new FormData(),
			});
			setDomesticCups([]);
			setDomesticLeague([]);
			setInternationCups([]);
			setGalleryPhotos([]);
			setGalleryVideos([]);
			setCoverImage("");
			if (document.getElementById("coverImage")) {
				document.getElementById("coverImage").src = "";
			}

			toast.success("Information updated successfully!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			setTimeout(
				() => history.push(`/admin/talents/${categoryId}`),
				1000
			);
		}
	};

	const inputRow = (data, param, idx) => (
		<tr
			key={data.id}
			className="row"
			style={{ marginLeft: "0", width: "100%" }}
		>
			<td className="col-3 mb-0 pt-2 form-group">
				<input
					value={data.season}
					onChange={(e) => handleInputs(e, "season", param, idx)}
					type="text"
					className="form-control"
				/>
			</td>
			<td className="col-6 mb-0 pt-2 form-group">
				<input
					value={data.league}
					onChange={(e) => handleInputs(e, "league", param, idx)}
					type="text"
					className="form-control"
				/>
			</td>
			<td className="col-3 mb-0 pt-2 form-group">
				<div className="clubButtons">
					<button
						onClick={(e) => deleteEntry(e, data.id, param)}
						className="btn btn-light deleteButton"
					>
						<i class="fa fa-trash-o" aria-hidden="true"></i>
					</button>
					<button
						// onClick={(e) => deleteEntry(e, data.id, param)}
						className="btn btn-light"
					>
						<i class="fa fa-bars" aria-hidden="true"></i>
					</button>
					<button
						// onClick={(e) => deleteEntry(e, data.id, param)}
						className="btn btn-light text-success"
					>
						<i class="fa fa-plus" aria-hidden="true"></i>
					</button>
				</div>
			</td>
		</tr>
	);

	const affilicationTable = (data, param) => (
		<div style={{ color: "black" }} className="row">
			<div className="table-responsive">
				<table className="table table-borderless">
					<thead className="mt-0">
						<tr
							className="row rowWidth mt-2"
							style={{ marginLeft: "0", marginRight: "0" }}
						>
							<th
								style={{ padding: "0", paddingLeft: "15px" }}
								className="col-3 mb-0 form-group"
								scope="col"
							>
								<label>Year</label>
							</th>
							<th
								style={{ padding: "0", paddingLeft: "15px" }}
								className="col-6 mb-0 form-group"
								scope="col"
							>
								<label className="labelClass">{param}</label>
							</th>
							<th
								style={{ padding: "0", paddingLeft: "15px" }}
								className="col-3 mb-0 form-group"
								scope="col"
							></th>
						</tr>
					</thead>
					<tbody>
						{data.map((obj, idx) => inputRow(obj, param, idx))}
					</tbody>
				</table>
			</div>
		</div>
	);

	const clubAffiliation = () => (
		<div
			style={{ borderRadius: "8px" }}
			className="col-10 mt-5 alert alert-info"
		>
			<div style={{ color: "black" }} className="row mt-5">
				<div className="col-md-6">
					<h3>Club Affiliation</h3>
				</div>
			</div>
			{Object.keys(clubAffiliationData).length
				? Object.keys(clubAffiliationData).map((season) => (
						<>
							<div
								style={{ color: "black" }}
								className="row mt-3"
							>
								<div className="col-sm-9 form-group mb-0">
									<label style={{ fontWeight: "900" }}>
										{season}
									</label>
								</div>
								<div className="col-sm-3 mb-0 py-0">
									<button
										type="button"
										onClick={() => addNew(season)}
										className="btn btn-block btn-success minWidthOfButton"
									>
										ADD NEW
									</button>
								</div>
							</div>
							{clubAffiliationData[season].length > 0 &&
								affilicationTable(
									clubAffiliationData[season],
									season
								)}
						</>
				  ))
				: null}
			{/* <div style={{ color: "black" }} className="row mt-3">
                <div className="col-sm-9 form-group mb-0">
                    <label style={{ fontWeight: "900" }}>Domestic Cups</label>
                </div>
                <div className="col-sm-3 mb-0 py-0">
                    <button type="button" onClick={() => addNew("domesticCups")} className="btn btn-block btn-success minWidthOfButton">ADD NEW</button>
                </div>
            </div>
            {domesticCups.length > 0 && affilicationTable(domesticCups, "domesticCups")}
            <div style={{ color: "black" }} className="row mt-3">
                <div className="col-sm-9 form-group mb-0">
                    <label style={{ fontWeight: "900" }}>International Cups</label>
                </div>
                <div className="col-sm-3 mb-0 py-0">
                    <button type="button" onClick={() => addNew("internationCups")} className="btn btn-block btn-success minWidthOfButton">ADD NEW</button>
                </div>
            </div>
            {internationCups.length > 0 && affilicationTable(internationCups, "internationCups")} */}
			{/* <div className="row">
                <div className="col-12 form-group mt-3 mb-0">
                    <label style={{ fontWeight: "900" }}>National Team</label>
                </div>
                <button style={{ marginLeft: "15px" }} className="btn btn-light mt-0 text-success"><i class="fa fa-plus" aria-hidden="true"></i></button>
            </div> */}
		</div>
	);

	const photosUI = () => (
		<div
			style={{ borderRadius: "8px" }}
			className="col-10 mt-5 alert alert-info"
		>
			<div style={{ color: "black" }} className="row mt-4">
				<div className="col-xl-7">
					<h3>Photos</h3>
				</div>
				<div className="col-xl-5 clubButtons">
					<input
						type="file"
						name="photoFile"
						id="inputPhoto"
						accept="image/*"
						onChange={loadFile}
						ref={inputPhoto}
						style={{ display: "none" }}
						multiple
					/>
					<button
						onClick={() => inputPhoto.current.click()}
						className="btn btn-success buttonPadding galleryButtons"
					>
						Add photo
					</button>
					<Popup
						trigger={
							<button className="btn btn-success galleryButtons">
								Add by link
							</button>
						}
						position="bottom"
					>
						<div className="form-group m-2">
							<input
								value={tempImageLink}
								name="tempImageLink"
								onChange={handleBio}
								type="text"
								style={{ backgroundColor: "#d1ecf1" }}
								className="form-control mt-3"
							/>
							<button
								onClick={() => {
									setGalleryPhotos([
										...galleryPhotos,
										tempImageLink,
									]);
									setBio({ ...bio, tempImageLink: "" });
								}}
								className="btn btn-success buttonPadding mt-3 py-1 px-5"
							>
								ADD
							</button>
						</div>
					</Popup>
				</div>
			</div>
			<div
				className="my-4"
				style={{
					overflowY: "scroll",
					overflowX: "hidden",
					maxHeight: "420px",
				}}
			>
				<section id="photos">
					{galleryPhotos.length > 0 &&
						galleryPhotos.map((imageSrc, idx) => (
							<div className="image-wrapper">
								<span
									onClick={() => deletePost(idx, "photo")}
									className="close"
								>
									&times;
								</span>
								<img
									src={getURLObj(imageSrc)}
									alt="No image found!"
								/>
							</div>
						))}
				</section>
			</div>
		</div>
	);

	const videosUI = () => (
		<div
			style={{ borderRadius: "8px" }}
			className="col-10 mt-5 alert alert-info"
		>
			<div style={{ color: "black" }} className="row mt-4">
				<div className="col-xl-7">
					<h3>Videos</h3>
				</div>
				<div className="col-xl-5 clubButtons">
					<input
						type="file"
						name="videoFile"
						id="videoInput"
						accept="video/*"
						onChange={loadFile}
						ref={inputVideo}
						style={{ display: "none" }}
						multiple
					/>
					<button
						type="button"
						onClick={() => inputVideo.current.click()}
						className="btn btn-success buttonPadding galleryButtons"
					>
						Add videos
					</button>
					<Popup
						trigger={
							<button className="btn btn-success galleryButtons">
								Add by link
							</button>
						}
						position="bottom"
					>
						<div className="form-group m-2">
							<input
								value={tempVideoLink}
								name="tempVideoLink"
								onChange={handleBio}
								type="text"
								style={{ backgroundColor: "#d1ecf1" }}
								className="form-control mt-3"
							/>
							<button
								onClick={() => {
									setGalleryVideos([
										...galleryVideos,
										tempVideoLink,
									]);
									setBio({ ...bio, tempVideoLink: "" });
								}}
								className="btn btn-success buttonPadding mt-3 py-1 px-5"
							>
								ADD
							</button>
						</div>
					</Popup>
				</div>
			</div>
			<div
				className="my-4"
				style={{
					overflowY: "scroll",
					overflowX: "hidden",
					maxHeight: "420px",
				}}
			>
				<div className="row">
					{galleryVideos.length > 0 &&
						galleryVideos.map((videoSrc, idx) => (
							<div
								key={`${videoSrc}${idx}`}
								className="col-xl-4 mt-3"
							>
								{/* <i className="fa fa-times-circle text-white closeIcon" aria-hidden="true"></i> */}
								<div className="image-wrapper">
									<span
										onClick={() => deletePost(idx, "video")}
										className="close"
									>
										&times;
									</span>
									<ReactPlayer
										controls
										className="videos"
										url={getURLObj(videoSrc)}
									/>
								</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);

	const socialMedias = () => (
		<div
			style={{ borderRadius: "8px" }}
			className="col-10 mt-5 alert alert-info"
		>
			<div style={{ color: "black" }} className="row mt-4">
				<div className="col-md-6 form-group">
					<h3>Social Media</h3>
					{/* {categoryName === "Football Players" && <label style={{ fontWeight: "900" }}>Soccerway</label>} */}

					<div className="form-group">
						<label>Facebook</label>
						<input type="text" className="form-control" />
					</div>
					<div className="form-group">
						<label>Instagram</label>
						<input type="text" className="form-control" />
					</div>
					<div className="form-group">
						<label>Twitter</label>
						<input type="text" className="form-control" />
					</div>
					<div className="form-group">
						<label>Head</label>
						<input type="text" className="form-control" />
					</div>
				</div>
			</div>
		</div>
	);

	const RewardsUI = () => (
		<div
			style={{ borderRadius: "8px" }}
			className="col-10 mt-5 alert alert-info"
		>
			<div style={{ color: "black" }} className="row mt-4">
				<div className="col-md-5">
					<h3>Rewards</h3>
				</div>
				{talentId && (
					<div className="col-md-7 text-right">
						<button
							type="button"
							className="btn btn-success galleryButtons"
							//   onClick={() => history.push(`/admin/rewards/add/${talentId}`)}
							onClick={() =>
								history.push(
									`/admin/rewards/add/${talentData.talents.categoryId}/${talentData.talentNumber}/${talentData.cardNumber}`
								)
							}
						>
							Add Reward
						</button>
					</div>
				)}
			</div>
			{rewards.length > 0 ? (
				<div
					className="my-4"
					style={{
						overflowY: "scroll",
						overflowX: "hidden",
						maxHeight: "420px",
					}}
				>
					<section id="photos">
						{rewards.map((imageSrc, idx) => (
							<div
								className="image-wrapper"
								key={`${imageSrc.data.cover_image[0]}${idx}`}
							>
								<span className="rewards badge badge-light">
									{imageSrc.data.rewards.name}
								</span>
								<img
									src={`${awsLINK}/${imageSrc.data.cover_image[0]}`}
									alt="No image found!"
								/>
							</div>
						))}
					</section>
				</div>
			) : (
				<div className="mt-3">
					<h5>No Rewards!</h5>
				</div>
			)}
		</div>
	);

	const Bio = () => (
		<div
			style={{ borderRadius: "8px" }}
			className="col-10 mt-5 alert alert-info"
		>
			<div style={{ color: "black" }} className="row mt-4">
				<div className="col-md-6 form-group">
					<h3>Card Values</h3>
					{talentId && (
						<div className="form-group">
							<label
								title={`https://jetnft.com/metadata/${talentData.talentNumber}/${talentData.cardNumber}`}
								onClick={() =>
									(window.location.href = `https://jetnft.com/metadata/${talentData.talentNumber}/${talentData.cardNumber}`)
								}
								style={{ opacity: "70%", cursor: "pointer" }}
							>
								metadata/{talentData.talentNumber}/
								{talentData.cardNumber}
							</label>
						</div>
					)}

					{/* <div className="form-group">
                        <label style={{opacity:"70%"}}>First Name *</label>
                        <input
                            // disabled={Boolean(talentId)}
                            value={FirstName}
                            name="FirstName"
                            onChange={handleBio}
                            type="text"
                            className="form-control" />
                    </div>
                    <div className="form-group">
                        <label style={{opacity:"70%"}}>Last Name *</label>
                        <input
                            // disabled={Boolean(talentId)}
                            value={LastName}
                            name="LastName"
                            onChange={handleBio}
                            type="text"
                            className="form-control" />
                    </div> */}
					<div className="form-group">
						<label style={{ opacity: "70%" }}>
							Max Jetcard Edition
						</label>
						<input
							value={numOfCards}
							name="numOfCards"
							onChange={handleBio}
							type="number"
							className="form-control"
						/>
					</div>
					{/* {talentId && (<div className="form-group">
                        <label style={{opacity:"70%"}}>Serial Number</label>
                        <input
                            disabled
                            value={serialNumber}
                            name="serialNumber"
                            onChange={handleBio}
                            type="number"
                            className="form-control" />
                    </div>)} */}
					{/* <div className="form-group">
                        <label style={{opacity:"70%"}}>Max Jetcard Edition</label>
                        <input
                            value={maxEdition}
                            name="maxEdition"
                            onChange={handleBio}
                            type="text"
                            className="form-control" />
                    </div> */}
					<div className="form-group col-6 pl-0">
						<label style={{ opacity: "70%" }}>ETH</label>
						<input
							value={eth}
							name="eth"
							onChange={handleBio}
							type="number"
							className="form-control"
						/>
					</div>
					{/* <div className="form-group">
                        <label style={{opacity:"70%"}}>Purchase on Mintable</label>
                        <input
                            value={purchaseOnMintable}
                            name="purchaseOnMintable"
                            onChange={handleBio}
                            type="text"
                            className="form-control" />
                    </div>
                    <div className="form-group">
                        <label style={{opacity:"70%"}}>Trade on Mintable / Open Sea</label>
                        <input
                            value={tradeOnMintable}
                            name="tradeOnMintable"
                            onChange={handleBio}
                            type="text"
                            className="form-control" />
                    </div> */}
				</div>
			</div>
		</div>
	);

	const renderCoverImage = (cover) => (
		<div
			style={{ borderRadius: "8px" }}
			className="col-10 mt-5 alert alert-info"
		>
			<div style={{ color: "black" }} className="row mt-4">
				<div className="col-md-6 form-group">
					<h3>{cover}</h3>
					<div>
						<p>
							<img
								src={coverImage ? coverImage : ""}
								style={{
									width: "80%",
									height: "auto",
									borderRadius: "8px",
								}}
								className="mt-3"
								id="coverImage"
							/>

							<video
								width="100%"
								height="auto"
								controls
								autoplay
								style={{
									display:
										coverVideo !== "" ? "block" : "none",
								}}
								id="coverVideo"
							></video>
						</p>
						<p>
							<input
								type="file"
								accept="image/*, video/*"
								name="coverImage"
								id="file"
								onChange={loadFile}
								style={{ display: "none" }}
							/>
						</p>
						<p className="m-0 p-0">
							<label for="file" style={{ cursor: "pointer" }}>
								<span className="btn btn-success mt-4 px-5 py-1">
									Upload a photo
								</span>
							</label>
						</p>
					</div>
				</div>
			</div>
		</div>
	);

	const renderUI = (idx) => (
		<div
			style={{ borderRadius: "8px" }}
			className="col-10 mt-4 alert alert-info"
		>
			<div style={{ color: "black" }} className="row mt-4">
				<div className="col-md-6">
					<h3>{headings[idx]}</h3>
					{/* {(categoryName === "Football Players" &&
                        (headings[idx] === "Bio" || headings[idx] === "Social Media"))
                        && <p style={{ fontWeight: "900" }}>Soccerway</p>} */}

					{subHeadings[headings[idx]] &&
						subHeadings[headings[idx]].length > 0 &&
						subHeadings[headings[idx]].map((subHeading) => (
							<div className="form-group">
								{console.log(
									"inputs[subHeading] ",
									inputs[subHeading]
								)}
								<label style={{ opacity: "70%" }}>
									{subHeading}
								</label>
								<input
									type="text"
									className="form-control"
									value={inputs[subHeading]}
									onChange={(e) =>
										handleChangeAll(e, subHeading)
									}
								/>
							</div>
						))}

					{categoryName === "Super Cars" &&
						headings[idx] === "Technical Specifications" && (
							<>
								<div className="form-group">
									<label style={{ opacity: "70%" }}>
										Brand
									</label>
									<input
										type="text"
										className="form-control"
										value={inputs["Brand"]}
										onChange={(e) =>
											handleChangeAll(e, "Brand")
										}
									/>
								</div>
								<div className="form-group">
									<label style={{ opacity: "70%" }}>
										Model
									</label>
									<input
										type="text"
										className="form-control"
										value={inputs["Model"]}
										onChange={(e) =>
											handleChangeAll(e, "Model")
										}
									/>
								</div>
							</>
						)}
				</div>
			</div>
		</div>
	);

	const publish = async () => {
		let firstName = "",
			lastName = "";
		if (inputs["Brand"])
			firstName =
				inputs["Brand"][0].toUpperCase() + inputs["Brand"].slice(1);
		if (inputs["Model"])
			lastName =
				inputs["Model"][0].toUpperCase() + inputs["Model"].slice(1);
		if (inputs["First Name"])
			firstName =
				inputs["First Name"][0].toUpperCase() +
				inputs["First Name"].slice(1);
		if (inputs["Last Name"])
			lastName =
				inputs["Last Name"][0].toUpperCase() +
				inputs["Last Name"].slice(1);

		setIsPublishing(true);
		let listing = {};
		listing["id"] = nanoid(10); //use this in POST URL;
		listing["tags"] = ["JET", "JETCOIN", "JETCARD", "NFT", "INTERACTIVE"];
		listing[
			"url"
		] = `https://jetnft.com/metadata/${talentData.talentNumber}/${talentData.cardNumber}`;
		listing["owner_sub"] = "jetcoin";
		listing["token_id"] = nanoid(4);
		listing["title"] = firstName.trim() + " " + lastName.trim();
		listing["sub_title"] = categoryName;
		listing["copyright_transfer"] = false;
		listing["resellable"] = true;
		listing["description"] = inputs["Description"];
		listing["preview_images"] = [coverImage];
		listing["animation_url"] = "";
		listing["buyNowPrice"] = parseFloat(eth);
		listing["seriesID"] = parseInt(talentData.talentNumber);

		try {
			setAuthToken(token);
			let result = await Instance.post(requests.addNFT, {
				...listing,
			}).catch((error) => {
				setIsPublishing(false);
				if (error.response) {
					toast.error(error?.response?.data?.message, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			});
			if (result && result.data) {
				setIsPublishing(false);
				toast.success("NFT minted successfully");
			}
		} catch (error) {
			setIsPublishing(false);
			if (error.response) {
				toast.error(error?.response?.data?.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}
	};

	return (
		<App>
			<div className="container1 mt-5">
				<h3>
					Add<span style={{ fontSize: "35px" }}>&#47;</span>Edit Name
					NFT
				</h3>
				{headings &&
					headings.length > 0 &&
					headings.map((heading, idx) => (
						<>
							{heading === "Club Affiliation" &&
								clubAffiliation()}
							{heading === "Photos" && photosUI()}
							{heading === "Videos" && videosUI()}
							{heading === "Rewards" && RewardsUI()}
							{heading === "Cover" && renderCoverImage(heading)}
							{heading === "Cover Art" &&
								renderCoverImage(heading)}
							{heading !== "Club Affiliation" &&
								heading !== "Cover" &&
								heading !== "Cover Art" &&
								heading !== "Photos" &&
								heading !== "Videos" &&
								heading !== "Rewards" &&
								renderUI(idx)}
						</>
					))}
				{Bio()}
				<div className="col-10 mt-4 clubButtons mb-5">
					<div className="btnGroup">
						<button
							type="button"
							onClick={submitData}
							disabled={loading}
							className="btn btn-success px-5"
						>
							{loading && (
								<>
									<i class="fa fa-circle-o-notch fa-spin"></i>
									&nbsp;
								</>
							)}
							SAVE
						</button>{" "}
						&nbsp;&nbsp;
						{talentId && (
							<button
								type="button"
								disabled={isPublishing}
								className="btn btn-success px-4"
								onClick={publish}
							>
								{isPublishing && (
									<>
										<i class="fa fa-circle-o-notch fa-spin"></i>
										&nbsp;
									</>
								)}
								Publish
							</button>
						)}
					</div>
					{talentId && (
						<button
							type="button"
							onClick={deleteTalent}
							className="btn btn-outline-danger"
						>
							DELETE CARD
						</button>
					)}
				</div>
			</div>
			<ToastContainer autoClose={2000} />
		</App>
	);
};

export default EditNFTCard;
