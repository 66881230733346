import React, { useEffect, useState } from "react";
import App from "../../App";
import { isAuthenticated } from "../../requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Instance, setAuthToken } from "../../axios";
import requests from "../../request";

const AddEditRewards = ({ history, match }) => {
  let {
    obj: { data },
  } = isAuthenticated();
  let token = data;
  let awsLINK =
    "https://digitalcard-static-files.s3.ap-southeast-1.amazonaws.com";
  let rewardId = match.params.rewardId;
  let categoryID = parseInt(match.params.categoryId);
  let seriesNumber = parseInt(match.params.seriesNumber);
  let talentId = parseInt(match.params.talentId);

  const [inputs, setInputs] = useState({
    name: "",
    series: "",
    categoryId: "",
    nft: "",
    type: "",
    formData: new FormData(),
  });
  const [coverImage, setCoverImage] = useState("");
  const [loading, setLoading] = useState(false);

  // category list
  const [categoryList, setCategoryList] = useState([]);

  const { name, series, categoryId, nft, type, formData } = inputs;

  setAuthToken(token);
  const fetchRewardById = async () => {
    const response = await Instance.get(
      `${requests.fetchRewardById}/${rewardId}`
    ).catch((error) => {
      if (error.response) {
        toast.error(error.response.data.error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
    if (response && response.data) {
      let data = response.data;
      let newObj = {
        name: data.data.rewards.name,
        series: data.data.rewards.series,
        categoryId: data.data.rewards.categoryId,
        nft: data.data.rewards.nft,
        type: data.data.rewards.type,
        formData: new FormData(),
      };
      if (
        data.data["cover_image"][0] !== "undefined" &&
        data.data["cover_image"][0] !== undefined
      ) {
        setCoverImage(`${awsLINK}/${data.data["cover_image"][0]}`);
      }
      setInputs(newObj);
    }
  };

  useEffect(() => {
    if (talentId) {
      setInputs({
        ...inputs,
        series: seriesNumber,
        categoryId: categoryID,
        nft: talentId,
      });
      formData.set("series", seriesNumber);
      formData.set("categoryId", categoryID);
      formData.set("nft", talentId);
    }
    if (rewardId) {
      fetchRewardById();
    }
  }, []);

  const fetchCategoryList = async () => {
    const response = await Instance.get(
      `${requests.fetchAllCategoryList}`
    ).catch((error) => {
      if (error.response) {
        toast.error(error.response.data.error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
    if (response && response.data) {
      let data = response.data.data;
      setCategoryList(data);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    formData.set(e.target.name, e.target.value);
  };

  const loadFile = (e) => {
    if (e.target.files[0]) {
      var image = document.getElementById("coverImage");
      image.src = URL.createObjectURL(e.target.files[0]);
      formData.set("coverImage", e.target.files[0]);
    }
  };

  const validate = () => {
    if (!name) {
      toast.error("Please enter the name for reward.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return true;
    }
    if (!series) {
      toast.error("Please enter the series for reward.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return true;
    }
    if (!categoryId) {
      toast.error("Please enter the series for reward.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return true;
    }
    if (!nft) {
      toast.error(
        "Please enter nft number (serial number) of recipient card.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
      return true;
    }
    if (!type) {
      toast.error("Please select type of reward.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return true;
    }
    return false;
  };

  const onSubmit = async () => {
    setLoading(true);
    if (validate()) {
      return;
    }
    if (rewardId) {
      if (formData.get("coverImage") === null) {
        formData.set("coverImage", coverImage.replace(`${awsLINK}/`, ""));
      }
      formData.set("name", name);
      formData.set("series", series);
      formData.set("categoryId", categoryId);
      formData.set("nft", nft);
      formData.set("type", type);

      setAuthToken(token);
      const response = await Instance.post(
        `${requests.updateRewardById}/${rewardId}`,
        formData
      ).catch((error) => {
        if (error.response) {
          setLoading(false);
          toast.error(error.response.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      if (response && response.data) {
        setLoading(false);
        toast.success("Reward updated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // setInputs({
        //     name:"",
        //     nft:"",
        //     type:"",
        //     formData: new FormData()
        // })
        // document.getElementById("coverImage").src = ""
      }
    } else {
      setAuthToken(token);
      const response = await Instance.post(
        requests.postAddReward,
        formData
      ).catch((error) => {
        if (error.response) {
          setLoading(false);
          toast.error(error.response.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      if (response && response.data) {
        setLoading(false);
        toast.success("Reward added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setInputs({
          name: "",
          series: "",
          categoryId: "",
          nft: "",
          type: "",
          formData: new FormData(),
        });
        document.getElementById("coverImage").src = "";
      }
    }
  };

  const deleteReward = async () => {
    setAuthToken(token);
    const response = await Instance.post(
      `${requests.deleteRewardById}/${rewardId}`
    ).catch((error) => {
      if (error.response) {
        toast.error(error.response.data.error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
    if (response && response.data) {
      toast.success("Reward removed successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setInputs({
        name: "",
        series: "",
        categoryId: "",
        nft: "",
        type: "",
        formData: new FormData(),
      });
      document.getElementById("coverImage").src = "";
      setTimeout(() => history.push("/admin/rewards"), 1000);
    }
  };

  return (
    <App>
      <div className="container1 mt-5">
        <h3>
          Add<span style={{ fontSize: "35px" }}>&#47;</span>Edit Rewards
        </h3>
        <div
          style={{ borderRadius: "8px" }}
          className="col-10 mt-4 alert alert-info"
        >
          <div style={{ color: "black" }} className="row mt-4">
            <div className="col-lg-6">
              <div className="form-group">
                <label style={{ opacity: "70%" }}>Name</label>
                <input
                  type="text"
                  value={name}
                  name="name"
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label style={{ opacity: "70%" }}>Series</label>
                <input
                  type="text"
                  value={series}
                  name="series"
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label style={{ opacity: "70%" }}>Category ID</label>
                &nbsp;
                <select
                  name="categoryId"
                  value={categoryId}
                  onChange={handleChange}
                  style={{ padding: "10px" }}
                >
                  <option>Select Cateory</option>
                  {categoryList.map((e, key) => {
                    return (
                      <option key={key} value={e.categoryId}>
                        {e.category.categoryName}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-group">
                <label style={{ opacity: "70%" }}>NFT</label>
                <input
                  type="number"
                  name="nft"
                  value={nft}
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label style={{ opacity: "70%" }}>Type</label>
                <div className="switch-field">
                  <div>
                    <input
                      type="radio"
                      id="radio-one"
                      checked={type === "physical"}
                      onChange={handleChange}
                      name="type"
                      value="physical"
                    />
                    <label className="px-4 py-2" for="radio-one">
                      Physical
                    </label>{" "}
                    &nbsp;
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="radio-two"
                      checked={type === "virtual"}
                      onChange={handleChange}
                      name="type"
                      value="virtual"
                    />
                    <label className="px-4 py-2" for="radio-two">
                      Virtual
                    </label>{" "}
                    &nbsp;
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="radio-three"
                      checked={type === "crypto"}
                      onChange={handleChange}
                      name="type"
                      value="crypto"
                    />
                    <label className="px-4 py-2" for="radio-three">
                      Crypto
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-center">
                <p>
                  <img
                    src={coverImage ? coverImage : ""}
                    style={{
                      width: "80%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                    id="coverImage"
                  />
                </p>
                <p>
                  <input
                    type="file"
                    accept="image/*"
                    name="coverImage"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                </p>
                <p>
                  <label for="file" style={{ cursor: "pointer" }}>
                    <span className="btn btn-success mt-4 px-5 py-1">
                      Upload a photo
                    </span>
                  </label>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-10 mt-4 clubButtons mb-5">
          <button
            disabled={loading}
            onClick={onSubmit}
            type="button"
            className="btn btn-success px-5"
          >
            {loading && (
              <>
                <i class="fa fa-circle-o-notch fa-spin"></i>
                &nbsp;
              </>
            )}
            SAVE
          </button>
          {rewardId && (
            <button
              onClick={deleteReward}
              type="button"
              className="btn btn-outline-danger"
            >
              DELETE REWARDS
            </button>
          )}
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </App>
  );
};

export default AddEditRewards;
