import React from 'react';
import NavBar from './components/NavBar';
import "./style.css"

function App({children}) {
  return (
    <div>
      <NavBar />
      <div>{children}</div>
    </div>
  );
}

export default App;
