import {
	useTable,
	usePagination,
	useSortBy,
	useGlobalFilter,
} from "react-table";
import React from "react";
import "../style.css";
import GlobalFilter from "./GlobalFilter";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

const Table = ({
	columns,
	data,
	title,
	btnName = "",
	btnLink = "#",
	show = false,
}) => {
	columns = React.useMemo(() => columns, []);
	data = React.useMemo(() => data, []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, globalFilter },
		setGlobalFilter,
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const tableUI = () => (
		<>
			{/* <GlobalFilter /> */}
			<div className="table-responsive">
				<table {...getTableProps()} className="table table-borderless">
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th
										{...column.getHeaderProps(
											column.getSortByToggleProps()
										)}
									>
										{column.render("Header")}
										<span>
											{" "}
											&nbsp;
											{column.isSorted ? (
												column.isSortedDesc ? (
													<i
														className="fa fa-chevron-down"
														aria-hidden="true"
													></i>
												) : (
													<i
														class="fa fa-chevron-up"
														aria-hidden="true"
													></i>
												)
											) : (
												""
											)}
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<>
									<tr
										className="table-info"
										{...row.getRowProps()}
									>
										{row.cells.map((cell) => {
											return (
												<td
													style={{ padding: "7px" }}
													className=""
													{...cell.getCellProps()}
												>
													{cell.render("Cell")}
												</td>
											);
										})}
									</tr>
									{/* {show && (
                    <div className="alert alert-info">HELLO</div>
                )} */}
									<div className="blankDiv"></div>
								</>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="row mb-4">
				<div className="col-lg-8 pageNation">
					<button
						className="btn btn-outline-info btn-sm"
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
					>
						{"<<"}
					</button>{" "}
					<button
						className="btn btn-outline-info btn-sm"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					>
						{"<"}
					</button>
					<span className="px-4 m-auto p-auto">
						Page{" "}
						<strong>
							{pageIndex + 1} of {pageOptions.length}
						</strong>{" "}
					</span>
					<button
						className="btn btn-outline-info btn-sm"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					>
						{">"}
					</button>{" "}
					<button
						className="btn btn-outline-info btn-sm"
						onClick={() => gotoPage(pageCount - 1)}
						disabled={!canNextPage}
					>
						{">>"}
					</button>{" "}
				</div>
				<div className="col-lg-4 mt-2 clubButtons pageNation">
					<div>
						<span className="m-auto p-auto">Go to page:</span>
					</div>
					<div className="text-center">
						<input
							className="form-control"
							type="number"
							defaultValue={pageIndex + 1}
							onChange={(e) => {
								const page = e.target.value
									? Number(e.target.value) - 1
									: 0;
								gotoPage(page);
							}}
							style={{ maxWidth: "70px", height: "30px" }}
						/>
					</div>
					<div className="selectOption">
						<select
							className="form-control"
							value={pageSize}
							onChange={(e) => {
								setPageSize(Number(e.target.value));
							}}
							style={{
								height: "30px",
								maxWidth: "110px",
								float: "right",
							}}
						>
							{[10, 20, 30, 40, 50].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
		</>
	);

	return (
		<>
			<div className="container1 mt-5">
				<div className="row">
					<div className="col-md-7">
						<h3 className="p-2">{title}</h3>
					</div>
					<div className="col-md-5">
						<div className="row">
							{!btnName && <div className="col-md-5"></div>}
							<div className="col-lg-7 col-sm-12">
								<GlobalFilter
									filter={globalFilter}
									setFilter={setGlobalFilter}
								/>
							</div>
							{btnName && btnName === "EXPORT" ? (
								<div className="col-lg-5 col-sm-12 text-center">
									<button
										type="button"
										className="btn btn-success floatRight px-4 my-1"
									>
										<CSVLink
											style={{
												textDecoration: "none",
												color: "white",
											}}
											data={data}
											filename={`ownerlist.csv`}
										>
											{btnName}
										</CSVLink>
									</button>
								</div>
							) : (
								<div className="col-lg-5 col-sm-12 text-center">
									<Link
										to={btnLink}
										type="button"
										style={{ width: "180.09px" }}
										className="btn btn-success floatRight px-4 my-1"
									>
										<p className="p-0 m-0">{btnName}</p>
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="mt-4">{tableUI()}</div>
			</div>
		</>
	);
};

export default Table;
