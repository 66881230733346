import React, { useEffect, useState } from 'react';
import App from '../../App';
import { isAuthenticated } from '../../requests';
import "../../style.css";
import AddCategoryModal from './AddCategoryModal';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom';
import Table from '../Table';
import {Instance,setAuthToken} from "../../axios"
import requests from "../../request"

import CategoryBar from './Talents/CategoryBar';

const OwnerList = ({history}) => {

    let {obj} = isAuthenticated();

    const [ownerListArr, setOwnerListArr] = useState([])

    const getAllOwners = async () => {
        setAuthToken(obj.data)
        const response = await Instance.get(requests.fetchAllOwners).catch((error) => {
            if(error.response){
                toast.error(error.response.data.error.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })

        if(response && response.data){
            setOwnerListArr(response.data.data)
        }
    }

    useEffect(() => {
        getAllOwners()
    }, [])

    const tableColumns = [
        {
            Header:"E-mail",
            accessor:"email",
            filter:"text",
            Cell:(props) => <p className="paddingTop">{props.value}</p>
        },
        {
            Header:"Wallet Address",
            accessor:"walletAddress",
            filter:"text",
            Cell:(props) => <p className="paddingTop">{props.value}</p>
        },
        {
            Header:"Quantity",
            accessor:"qty",
            filter:"text",
            Cell:(props) => <p className="paddingTop">{props.value}</p>
        },
    ]

       


    return (
        <App>
            <CategoryBar history={history}/>
            {<AddCategoryModal/>}
                <div className="mt-4">
                    {ownerListArr && ownerListArr.length>0 ? (
                        <Table 
                            title="Owner list of Cristiano Ronaldo" 
                            columns={tableColumns} 
                            data={ownerListArr}
                            btnLink="#"
                            btnName="EXPORT"
                        />
                    ) : (
                        <div className="container1 mt-5">
                        <div className="row">
                            <div className="col-md-7">
                                <h3 className="p-2">Owner list of Cristiano Ronaldo</h3>
                            </div>
                            <div className="col-md-5">
                                <div className="row">
                                    <div className="col-md-7">
                                        <input style={{paddingLeft:"45px"}} placeholder="Search" type="text" className="form-control py-3 my-1"/>
                                        <ion-icon 
                                            style={{position:"absolute",
                                                top:"10px",
                                                left:"23px",
                                                fontSize:"25px",
                                                opacity:"50%"}} 
                                            name="search-outline"></ion-icon>
                                    </div> 
                                    <div className="col-md-5 text-center">
                                        <Link type="button" className="btn btn-success floatRight px-4 my-1"><p className="p-0 m-0">EXPORT</p></Link>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        </div>
                    )}
            </div>
            <ToastContainer autoClose={2000}/>
        </App>
    )
}

export default OwnerList;
