import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import AddEditRewards from "./components/admin/AddEditRewards";
import EditAccountDetails from "./components/admin/EditAccountDetails";
import EditNFTCard from "./components/admin/EditNFTCard";
import Login from "./components/admin/Login";
import OwnerList from "./components/admin/OwnerList";
import Rewards from "./components/admin/Rewards";
import Talents from "./components/admin/Talents/Talents";
import Users from "./components/admin/Users";
import CardDetails from "./components/CardDetails";
import Wallet from "./components/Wallet";
import PrivateRoute from "./PrivateRoute";

const Routes = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path="/" exact component={Wallet} />
        <Route path="/admin" exact component={Login} />
        <Route
          path="/metadata/:categoryId/:talentId/:uniqueId"
          exact
          component={CardDetails}
        />
        <PrivateRoute
          path="/admin/manage-account"
          exact
          component={EditAccountDetails}
        />
        <PrivateRoute path="/admin/talents" exact component={Talents} />
        <PrivateRoute
          path="/admin/talents/:categoryId"
          exact
          component={Talents}
        />
        <PrivateRoute
          path="/admin/talents/ownerlist"
          exact
          component={OwnerList}
        />
        <PrivateRoute
          path="/admin/talents/edit/:categoryId/:talentId"
          exact
          component={EditNFTCard}
        />
        <PrivateRoute
          path="/admin/talents/add/:categoryId"
          exact
          component={EditNFTCard}
        />
        <PrivateRoute path="/admin/rewards" exact component={Rewards} />
        <PrivateRoute
          path="/admin/rewards/add"
          exact
          component={AddEditRewards}
        />
        <PrivateRoute
          path="/admin/rewards/add/:categoryId/:seriesNumber/:talentId"
          exact
          component={AddEditRewards}
        />
        <PrivateRoute
          path="/admin/rewards/edit/:rewardId"
          exact
          component={AddEditRewards}
        />
        <PrivateRoute path="/admin/users" exact component={Users} />
      </Switch>
    </HashRouter>
  );
};

export default Routes;
