import React from 'react'

const GlobalFilter = ({filter, setFilter}) => {
    return (
        <>
            <input 
                style={{paddingLeft:"45px"}}
                value={filter || ''}
                onChange={(e) => setFilter(e.target.value)} 
                placeholder="Search" type="text" 
                className="form-control py-3 my-1"/>
            <ion-icon 
                style={{position:"absolute",
                    top:"10px",
                    left:"23px",
                    fontSize:"25px",
                    opacity:"50%"}} 
                name="search-outline"></ion-icon>
        </>
    )
}

export default GlobalFilter
