import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { isAuthenticated } from "../../requests";
import { ToastContainer, toast } from "react-toastify";
import { Instance, setAuthToken } from "../../axios";
import requests from "../../request";

const AddCategoryModal = ({ reload, setReload = (f) => f }) => {
	const animatedComponents = makeAnimated();

	let { obj } = isAuthenticated();

	const [forceReload, setForceReload] = useState(false);
	const [modalInputs, setModalInputs] = useState({
		tempName: "",
		categoryName: "",
		headings: [],
	});
	const [displayUtility, setDisplayUtility] = useState(false);
	const [selectedHeadings, setSelectedHeadings] = useState(null);
	const [selectedSubHeadings, setSelectedSubHeadings] = useState({});
	const [utilities, setUtilities] = useState({
		addHeading: "",
		addSubHeading: "",
	});
	const [error, setError] = useState("");
	const [headingOptions, setHeadingOptions] = useState([]);
	const [subHeadingOptions, setSubHeadingOptions] = useState([]);

	let { addHeading, addSubHeading } = utilities;

	let { tempName, categoryName, headings } = modalInputs;

	const getSubheadings = async () => {
		setAuthToken(obj.data);
		const response = await Instance.get(requests.fetchAllSubHeadings).catch(
			(error) => {
				if (error.response) {
					toast.error(error.response.data.error.message, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			}
		);

		if (response && response.data) {
			// console.log("SUB HEADINGS",response.data.data)
			let obj = [];
			if (response.data.data.length > 0) {
				response.data.data.forEach((element) => {
					if (element.data) {
						obj.push({
							value: `${element.data}`,
							label: `${element.data}`,
						});
					}
				});
			}
			setSubHeadingOptions(obj);
		}
	};

	const getHeadings = async () => {
		setAuthToken(obj.data);
		const response = await Instance.get(requests.fetchAllHeadings).catch(
			(error) => {
				if (error.response) {
					toast.error(error.response.data.error.message, {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			}
		);

		if (response && response.data) {
			// console.log(" HEADINGS",response.data.data)
			let obj = [];
			if (response.data.data.length > 0) {
				response.data.data.forEach((element) => {
					if (element.data) {
						obj.push({
							value: `${element.data}`,
							label: `${element.data}`,
						});
					}
				});
			}
			setHeadingOptions(obj);
		}
	};

	useEffect(() => {
		// getCategories()
		getHeadings();
		getSubheadings();
	}, [forceReload]);

	useEffect(() => {
		let obj = {};
		headings.forEach((element) => {
			obj[element] = [];
		});
		setSelectedSubHeadings(obj);
	}, [headings]);

	const handleChange = (e) => {
		setModalInputs({ ...modalInputs, [e.target.name]: e.target.value });
	};

	const handleOptions = (selectedHeadings) => {
		setSelectedHeadings(selectedHeadings);
	};

	const handleUtilities = (e) => {
		setUtilities({ ...utilities, [e.target.name]: e.target.value });
	};

	const AnimatedMultiHeadings = () => {
		return (
			<Select
				value={selectedHeadings}
				onChange={handleOptions}
				closeMenuOnSelect={false}
				components={animatedComponents}
				isMulti
				options={headingOptions}
			/>
		);
	};

	const handleSubHeadings = (selectedOptions, param) => {
		let newArr = selectedOptions.map((option) => option.value);
		// console.log("NEW ARR",newArr)
		setSelectedSubHeadings({ ...selectedSubHeadings, [param]: newArr });
	};

	const AnimatedMultiSubHeadings = (param) => {
		return (
			<Select
				onChange={(selected) => handleSubHeadings(selected, param)}
				closeMenuOnSelect={false}
				components={animatedComponents}
				isMulti
				options={subHeadingOptions}
			/>
		);
	};

	const addCategory = () => {
		setModalInputs({
			...modalInputs,
			categoryName: tempName,
			tempName: "",
		});
	};

	const addHeadings = () => {
		let newArr = selectedHeadings.map((option) => option.value);
		setModalInputs({
			...modalInputs,
			headings: newArr,
		});
		setSelectedHeadings(null);
	};

	const activeTab = (param) => {
		if (param === "utility") {
			if (displayUtility) {
				return "#2ecc72";
			}
		} else {
			if (!displayUtility) {
				return "#2ecc72";
			}
		}
	};

	const submitHeading = async () => {
		setAuthToken(obj.data);
		const response = await Instance.post(requests.postAddHeadings, {
			data: addHeading,
		}).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.error.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		});

		if (response && response.data) {
			// console.log("AXIOS ADD HEADINGS",response.data.data)
			toast.success("Heading has been added!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			setSelectedHeadings([]);
			setSelectedSubHeadings([]);
			setUtilities({
				...utilities,
				addHeading: "",
				addSubHeading: "",
			});
			setForceReload(!forceReload);
		}
	};

	const submitSubHeading = async () => {
		setAuthToken(obj.data);
		const response = await Instance.post(requests.postAddSubheadings, {
			data: addSubHeading,
		}).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.error.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		});

		if (response && response.data) {
			toast.success("Subheading has been added!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			setSelectedHeadings([]);
			setSelectedSubHeadings([]);
			setUtilities({
				...utilities,
				addHeading: "",
				addSubHeading: "",
			});
			setForceReload(!forceReload);
		}
	};

	const submitCategory = async () => {
		let objs = {};
		objs["categoryName"] = categoryName;
		objs["heading"] = headings;
		objs["subheading"] = selectedSubHeadings;

		setAuthToken(obj.data);
		const response = await Instance.post(requests.postAddCategory, {
			...objs,
		}).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.error.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		});

		if (response && response.data) {
			// console.log("POST CATEGORY",response.data)
			setModalInputs({
				...modalInputs,
				categoryName: "",
				tempName: "",
				headings: [],
			});
			setSelectedHeadings([]);
			setSelectedSubHeadings([]);
			setUtilities({
				...utilities,
				addHeading: "",
				addSubHeading: "",
			});
			setReload(!reload);
			toast.success("Category Added!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	};

	const popupModal = () => (
		<div
			className="modal fade"
			id="exampleModal"
			tabindex="-1"
			role="dialog"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div
						className="modal-header text-white"
						style={{ backgroundColor: "#2f2f3c" }}
					>
						<div
							className="col-10"
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<h5
								onClick={() => setDisplayUtility(false)}
								style={{
									cursor: "pointer",
									color: activeTab("add"),
								}}
								className="modal-title"
							>
								Add New Category
							</h5>
							<OverlayTrigger
								key="left"
								placement="bottom"
								overlay={<Tooltip>{"Add new utility"}</Tooltip>}
							>
								<h5
									onClick={() => setDisplayUtility(true)}
									style={{
										cursor: "pointer",
										color: activeTab("utility"),
									}}
									className="modal-title"
								>
									Utilities
								</h5>
							</OverlayTrigger>
						</div>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true" className="text-white">
								&times;
							</span>
						</button>
					</div>
					<div className="modal-body">
						{displayUtility ? (
							<>
								<div className="row">
									<div className="col-12">
										<h5>Add new utilities</h5>
									</div>
									<div className="col-10 mt-3">
										<input
											type="text"
											value={addHeading}
											name="addHeading"
											onChange={handleUtilities}
											className="form-control"
											placeholder="Add new heading..."
										/>
									</div>
									<div
										className="col-2 mt-3"
										style={{ paddingLeft: "5px" }}
									>
										<button
											disabled={!addHeading}
											onClick={submitHeading}
											type="button"
											className="btn btn-success"
										>
											<i
												className="fa fa-plus"
												aria-hidden="true"
											></i>
										</button>
									</div>
									<div className="col-10 mt-3">
										<input
											type="text"
											name="addSubHeading"
											value={addSubHeading}
											onChange={handleUtilities}
											className="form-control"
											placeholder="Add new sub-heading..."
										/>
									</div>
									<div
										className="col-2 mt-3"
										style={{ paddingLeft: "5px" }}
									>
										<button
											disabled={!addSubHeading}
											onClick={submitSubHeading}
											type="button"
											className="btn btn-success"
										>
											<i
												className="fa fa-plus"
												aria-hidden="true"
											></i>
										</button>
									</div>
								</div>
							</>
						) : (
							<div className="row">
								{!categoryName && (
									<Fragment>
										<div className="col-10">
											<input
												value={tempName}
												onChange={handleChange}
												name="tempName"
												type="text"
												placeholder="Add new category"
												className="form-control"
											/>
										</div>
										<div
											className="col-2"
											style={{ paddingLeft: "5px" }}
										>
											<button
												type="button"
												onClick={addCategory}
												className="btn btn-success"
											>
												<i
													className="fa fa-plus"
													aria-hidden="true"
												></i>
											</button>
										</div>
									</Fragment>
								)}
								{categoryName && (
									<div className="col-12">
										<h6>Category Name: {categoryName}</h6>
										{headings.length === 0 && (
											<>
												<label>Select Headings:</label>
												<div>
													{AnimatedMultiHeadings()}
												</div>
												<OverlayTrigger
													key="left"
													placement="left"
													overlay={
														<Tooltip>
															{"Add Headings"}
														</Tooltip>
													}
												>
													<button
														type="button"
														onClick={addHeadings}
														className="btn mt-2 px-4 btn-success floatRight"
													>
														<i
															className="fa fa-plus"
															aria-hidden="true"
														></i>
													</button>
												</OverlayTrigger>
											</>
										)}
									</div>
								)}
								{headings.length > 0 && (
									<Fragment>
										<div className="col-12 mt-2">
											<p>Select Sub-Headings:</p>
											{headings.map((heading, idx) => (
												<div
													key={`${heading}${idx}`}
													className="mb-2"
												>
													<label>{heading}</label>
													<div>
														{AnimatedMultiSubHeadings(
															heading
														)}
													</div>
												</div>
											))}
										</div>
										<div className="col-6 offset-3 mt-2 text-center">
											<button
												onClick={submitCategory}
												className="btn btn-success btn-block"
												type="button"
											>
												Add Category
											</button>
										</div>
									</Fragment>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			{popupModal()}
			{/* <ToastContainer autoClose={2000}/> */}
		</>
	);
};

export default AddCategoryModal;
