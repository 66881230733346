import React, { useEffect, useState } from 'react'
import { Instance, setAuthToken } from '../axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import requests from '../request';
import "../style.css";
import "./Wallet.css";
import NFTDetails from './NFTDetails';
import CryptoJS from "crypto-js";
import { SECRET } from '../config';
import { getAllTokens, getTokenURI } from '../web3utils';

const CardDetails = ({history, match}) => {

    // let { obj: { data } } = isAuthenticated();
    // let token = data;

    let awsLINK = "https://digitalcard-static-files.s3.ap-southeast-1.amazonaws.com";

    let categoryId = match.params.categoryId;
    let talentId = match.params.talentId;
    let uniqueId = match.params.uniqueId;

    const [talentData, setTalentData] = useState("");
    const [forceReload, setForceReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [accountId, setAccountId] = useState('');

    useEffect(async() => {
        setLoading(true);
        if(localStorage.getItem('address') && localStorage.getItem('ttl') > new Date().getTime()){
            let address = CryptoJS.AES.decrypt(localStorage.getItem('address') || 1, SECRET).toString(CryptoJS.enc.Utf8);
            setAccountId(address);
            let ids = await getNFTs(address);
            let category = ids.filter((id)=>id.categoryId===categoryId);
            if(category?.[0]?.talentsId?.split(",").includes(talentId)) {
                if(talentId){
                    preload();
                }
            } else {
                localStorage.removeItem('ids');
                localStorage.removeItem('address');
                localStorage.removeItem('net');
                setLoading(false);
                history.push('/');
            }
        } else {
            localStorage.removeItem('ids');
            localStorage.removeItem('address');
            localStorage.removeItem('net');
            history.push('/');
        }
    }, [forceReload]);

    const getNFTs = async (account) => {
        setLoading(true);
        let tokens = await getAllTokens(account);
        // console.log("all the jet tokens the users have", tokens);
        let ids = []
        for(let i=0; i<tokens.length; i++){
            let item = tokens[i];
            let tokenData = await getTokenURI(item.token_id);
            // console.log("url for metadata of token is: ", tokenData);
            let arr = tokenData.split('https://jetnft.com/metadata/');
          
            if(arr && arr.length===2){
                let talentsId = arr[1].split('/')[1];
                let categoryId = arr[1].split('/')[0];
                let added = false;
                ids.map((obj)=>{
                    if(obj['categoryId']===categoryId){
                        obj['talentsId'] = [...obj['talentsId'], talentsId];
                        added = true;
                    }
                })
                if(!added){
                ids = [...ids, {categoryId: categoryId, talentsId: [talentsId]}]
                }
            } 
        };
        for(let i=0; i<ids.length; i++){
            ids[i]['talentsId'] = ids[i]['talentsId'].join();
        }
        return ids;
    };

    const preload = async () => {
        setAuthToken("")
        setLoading(true);
        const reponse = await Instance.get(`${requests.fetchTalentById}/${uniqueId}`).catch((error) => {
            if(error.response){
                toast.error(error.response.data.error.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })
        if(reponse && reponse.data){
            setTalentData(reponse.data.data);
        }
        setLoading(false)
    } 



    return (
        <div className="scrollBarRemove" style={{ minHeight: "100vh", backgroundColor: "#232327" }}>
            {loading ? (
                <div className="pt-5" style={{width:"100%", textAlign:"center"}}>
                    <div class="spinner-border text-light" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    {talentData && Object.keys(talentData).length && <NFTDetails data={talentData} accountId={accountId}/>}
                </>
            )}
        </div>
    )
}

export default CardDetails
