import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import "../../../src/style.css";
import logo from "../imgs/jetcoinWhite.png";
import { authenticate, isAuthenticated } from "../../requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Instance } from "../../axios";
import requests from "../../request";
import CryptoJS from "crypto-js";
import { SECRET } from "../../config";
import OtpInput from "react-otp-input";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    login: "",
    password: "",
    otp: "",
    redirect: false,
  });
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [resent, setResent] = useState(false);

  const [userName, setUserName] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { login, password, redirect, otp } = inputs;

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const onSubmit = async (param = "") => {
    // try {
    setIsLoading(true);
    if (!param) {
      let encryptedOTP = CryptoJS.AES.encrypt(otp, SECRET).toString();
      const response = await Instance.post(requests.otpVerification, {
        username: login,
        otp: encryptedOTP,
      }).catch((error) => {
        setIsLoading(false);
        if (error.response) {
          toast.error(error.response.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      if (response && response.data) {
        authenticate(response.data, () => {
          setUserName(response.data.username);
          setVerifyOtp(false);
          setIsLoading(false);
          setInputs({
            ...inputs,
            login: "",
            password: "",
            redirect: true,
            otp: "",
          });
        });
      }
    } else {
      let encryptedPassword = CryptoJS.AES.encrypt(password, SECRET).toString();
      const reponse = await Instance.post(requests.skipOTPLogin, {
        username: login,
        password: encryptedPassword,
      }).catch((error) => {
        setIsLoading(false);
        setVerifyOtp(false);
        if (error.response) {
          toast.error(error.response.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      if (reponse && reponse.data) {
        authenticate(reponse.data, () => {
          setUserName(reponse.data.username);
          setVerifyOtp(false);
          setIsLoading(false);
          setInputs({
            ...inputs,
            login: "",
            password: "",
            redirect: true,
            otp: "",
          });
        });
        // setVerifyOtp(true);
        // setIsLoading(false);
        // if(param==='resend') setResent(true);
      }
    }
  };

  const performRedirect = () => {
    if (redirect) {
      return <Redirect to="/admin/talents" />;
    }
    if (isAuthenticated()) {
      return <Redirect to="/admin/talents" />;
    }
  };

  return (
    <div
      className="text-white wrapper vcenter-item"
      style={{ backgroundColor: "#2f2f3c" }}
    >
      <div className="login centerLogin">
        <div className="text-center mb-5">
          <img
            src={logo}
            alt="jetCoin"
            style={{ height: "50px", width: "auto" }}
          />
        </div>
        <form>
          {verifyOtp ? (
            <div className="form-group w-100 flexBox">
              {resent && (
                <label htmlFor="">
                  New OTP has been sent to your email address
                </label>
              )}
              <label className="mb-3">
                Enter one-time-password sent to your email address
              </label>
              <OtpInput
                shouldAutoFocus={true}
                value={otp}
                onChange={(e) => setInputs({ ...inputs, otp: e })}
                className="otpInput"
                numInputs={6}
                separator={<span>&nbsp;-&nbsp;</span>}
              />
              <label className="mt-5">
                Didn't receive otp?{" "}
                <strong
                  className="cursorPointer"
                  onClick={() => onSubmit("resend")}
                >
                  Request again
                </strong>
              </label>
              <button
                type="button"
                onClick={() => onSubmit("")}
                className={`btn btn-lg btn-block btn-success text-white mt-2 w-50`}
              >
                <strong>Validate</strong>
              </button>
            </div>
          ) : (
            <>
              <div className="form-group w-100">
                <label>
                  <strong>Login</strong>
                </label>
                <input
                  type="text"
                  className="form-control text-white p-4"
                  value={login}
                  onChange={handleChange}
                  name="login"
                />
              </div>
              <div className="form-group w-100">
                <label>
                  <strong>Password</strong>
                </label>
                <div className="flex-center-div">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control text-white p-4 pr-5"
                    value={password}
                    onChange={handleChange}
                    name="password"
                  />
                  <i
                    className={
                      showPassword
                        ? "fa fa-eye-slash eye-icons"
                        : "fa fa-eye eye-icons"
                    }
                    aria-hidden="true"
                    onClick={() => setShowPassword(!showPassword)}
                  ></i>
                </div>
              </div>
              <button
                type="button"
                disabled={isLoading}
                onClick={() => onSubmit("submit")}
                className="btn btn-lg btn-block btn-success text-white mt-4"
              >
                <strong>CONFIRM AND CONTINUE</strong>
              </button>
            </>
          )}
        </form>
      </div>
      {performRedirect()}
      {/* <p>{JSON.stringify(inputs)}</p> */}
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default Login;
